import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useSearchParams } from 'react-router-dom'
import { useNavigate } from 'react-router';
import 'devextreme/data/odata/store';
import FileUploader from 'devextreme-react/file-uploader';
import devices from 'devextreme/core/devices';
import { debounce } from '../../utils/miscUtil';
import { useMap, useMapsLibrary } from '@vis.gl/react-google-maps';

import DataGrid, {
  Column,
  Pager,
  Paging,
  FilterRow,
  SearchPanel,
  Editing,
  Button as DGButton,
  Lookup,
} from 'devextreme-react/data-grid';
import { LoadIndicator } from 'devextreme-react/load-indicator';
import Button from 'devextreme-react/button';
import {
  Form,
  Label,
  SimpleItem,
  EmptyItem,
  GroupItem,
  ButtonItem,
  RequiredRule,
  EmailRule
} from 'devextreme-react/form';
import { useAuth } from '../../contexts/auth';
import { getResources, updateResource, deleteResource } from '../../api/api'
import { uploadFileChunk } from '../../api/s3'
import { Popup, Position, ToolbarItem } from 'devextreme-react/popup';

const isPhone = devices.current().deviceType === 'phone'

export default function Drivers() {
  const { user, refreshSession } = useAuth();
  const [customersList, setCustomersList] = useState([]);
  const [currentCustomer, setCurrentCustomer] = useState(null);
  const [loading, setLoading] = useState(true)
  const fileExtensions = ['.jpg', '.jpeg', '.png'];
  const dataGridRef = useRef(null);
  const formRef = useRef(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const [predictionResults, setPredictionResults] = useState([])
  const [autocompleteService, setAutocompleteService] = useState(null);
  const [sessionToken, setSessionToken] = useState();
  const map = useMap();
  const places = useMapsLibrary('places');
  const routes = useMapsLibrary('routes');


  useEffect(() => {
    if (user.signInUserSession) {
      getResources(user, onGetResourcesSuccess, onError);
    }
  }, [user && user.signInUserSession, user.signInUserSession.idToken.payload]);

  useEffect(() => {
    if (currentCustomer) {
      setCancelButtonOptions({
        disabled: false,
        text: 'Cancel',
        width: '100%',
        onClick: onCancel,
      });
      setSaveButtonOptions({
        disabled: !(currentCustomer.resource_details.name && currentCustomer.resource_details.email && currentCustomer.resource_details.phone),
        text: 'Save',
        type: 'default',
        width: '100%',
        onClick: onSave,
      });
    }
  }, [currentCustomer]);

  useEffect(() => {
    if (!places || !routes) return;
    setAutocompleteService(new places.AutocompleteService());
    setSessionToken(new places.AutocompleteSessionToken());
    // setDirectionsService(new routes.DirectionsService());
  }, [map, places, routes]);

  const fetchPredictions = useCallback(
    async (inputValue, form) => {
      if (!autocompleteService || !inputValue) {
        while (predictionResults.length > 0) {
          predictionResults.pop();
        }
        return;
      }

      const request = {input: inputValue, sessionToken};
      const response = await autocompleteService.getPlacePredictions(request);

      while (predictionResults.length > 0) {
        predictionResults.pop();
      }
      for (let i = 0; i < response.predictions.length; i++) {
        predictionResults.push(response.predictions[i]);
      }
    }, [autocompleteService, sessionToken]
  )


  const onCancel = () => {
    setCurrentCustomer(null);
  }

  const onSave = async () => {
    setSaveButtonOptions({
      disabled: true,
      text: 'Save',
      type: 'default',
      width: '100%',
      onClick: onSave,
    });
    updateResource(user, currentCustomer, onSaveSuccess, onError);
  }

  const onError = useCallback((error) => {
    console.log('error occured')
    if (error.status === 401) {
      refreshSession();
    } else {
      console.log(error);
    }
  }, [refreshSession]);

  const [cancelButtonOptions, setCancelButtonOptions] = useState({
    disabled: false,
    text: 'Cancel',
    width: '100%',
    onClick: onCancel,
  });

  const [saveButtonOptions, setSaveButtonOptions] = useState({
    disabled: false,
    text: 'Save',
    type: 'default',
    width: '100%',
    onClick: onSave,
  });

  // const onSaveSuccess = useCallback((data) => {
  //   setCurrentCustomer(null);
  // }, [])

  const onSaveSuccess = useCallback((data) => {
    getResources(user, onGetResourcesSuccess, onError);
    setCurrentCustomer(null);
    if (searchParams.get('setupMode')) {
      navigate('/home?setupMode=true');
    }
  }, [])

  const onGetResourcesSuccess = useCallback((data) => {
    let lst = [];
    for (let i = 0; i < data.length; i++) {
      if (data[i].resource_type === 'customer') {
        lst.push(data[i])
      }
    }
    setCustomersList(lst);
    setLoading(false)
  }, [])

  const removeRow = e => {
    deleteResource(user, e.key, onSaveSuccess, onError)
  }

  const phoneEditorOptions = { mask: '(000) 000-0000' };

  const onAdd = useCallback( () => {
    setCurrentCustomer(
      {
        resource_id: crypto.randomUUID(),
        resource_details: {},
        resource_type: 'customer',
      });
  }, [])

  const onFormDataChanged = (e) => {
    setSaveButtonOptions({
      disabled: !(currentCustomer.resource_details.name && currentCustomer.resource_details.email && currentCustomer.resource_details.phone),
      text: 'Save',
      type: 'default',
      width: '100%',
      onClick: onSave,
    });
  }


  if(!loading) {
    return (
      <React.Fragment>
        <div className="d-flex flex-row justify-content-between align-items-center content-block">
          <h2 className={''}>My Customers</h2>
          <Button
            type="default"
            text="Add Customer"
            onClick={onAdd}
          />
        </div>

        <DataGrid
          ref={dataGridRef}
          className={'dx-card wide-card'}
          dataSource={customersList}
          showBorders={false}
          focusedRowEnabled={false}
          defaultFocusedRowIndex={0}
          columnAutoWidth={true}
          columnHidingEnabled={true}
          onRowRemoved={removeRow}
        >
               
          <Editing
            mode="row"
            allowUpdating={true}
            allowDeleting={true}
            // allowAdding={true} 
          />
          <Editing
            mode="row"
            allowUpdating={true}
            allowDeleting={true}
          />

          <Paging defaultPageSize={50} />
          <Pager showPageSizeSelector={true} showInfo={true} />
          <SearchPanel
            visible={true}
            width="250"
            placeholder="Search..."
          />

          <Column
            dataField={'resource_details.name'}
            caption={'Name'}
            hidingPriority={3}
          >
            <RequiredRule />
          </Column>
          <Column
            dataField={'resource_details.email'}
            caption={'Email'}
            hidingPriority={1}
          />
          <Column
            dataField={'resource_details.phone'}
            caption={'Phone'}
            hidingPriority={0}
            editorOptions={phoneEditorOptions}
          >
            <RequiredRule />
          </Column>
          <Column
            dataField={'resource_details.address'}
            caption={'Address'}
            hidingPriority={0}
          />
          <Column
            dataField={'resource_details.notes'}
            editorType="dxTextArea"
            caption={'Internal notes'}
            hidingPriority={0}
          />    
          <Column type="buttons">
            <DGButton
                text="Edit"
                icon="fas fa-pen fs-6"
                hint="Edit"
                onClick={(e) => {
                  setCurrentCustomer(e.row.data);
                }}
            />
            <DGButton name="delete" />
          </Column>

          <Editing
            allowEditing={true}
            mode="popup"
            allowUpdating={true}
            allowDeleting={true}
            // allowAdding={true} 
          /> 
        </DataGrid>

        <Popup
          className="text-danger"
          visible={currentCustomer !== null}
          onHidden={() => setCurrentCustomer(null)}
          dragEnabled={false}
          showCloseButton={false}
          showTitle={true}
          fullScreen={isPhone}
          title={currentCustomer?.owner_id?"Edit Customer":"Add Customer"}
          container=".dx-viewport"
          width={380}
          height={720}
        >
            <Position at="center" my="center"collision="fit" />
            <ToolbarItem
              widget="dxButton"
              toolbar="bottom"
              location="before"
              options={saveButtonOptions}
            />
            <ToolbarItem
              widget="dxButton"
              toolbar="bottom"
              location="after"
              options={cancelButtonOptions}
            />


            <Form
              ref={formRef}
              id={'form'}
              formData={currentCustomer}
              labelLocation={'top'}
              onFieldDataChanged={onFormDataChanged}
            >
                <SimpleItem dataField="resource_details.name">
                  <Label text="Customer Name (Publicly Visible)"/>
                  <RequiredRule message="Customer name is required" />
                </SimpleItem>
                <SimpleItem 
                  dataField="resource_details.email" 
                  editorOptions={{
                    onInput: debounce( (e) => {
                      const val = e.event.currentTarget.value.trim()
                      console.log('val: ', val.trim())
                      formRef.current.instance.updateData('resource_details.email', '')
                      formRef.current.instance.updateData('resource_details.email', val)
            
                      // formRef.current.instance.option().formData.startDate
                      console.log('formRef: ', formRef.current.instance.option().formData.resource_details)
                    }, 100)
                  }}
                >
                  <Label text="Email"/>
                  <RequiredRule message="Email is required" />
                  <EmailRule message="Email is invalid" />
                </SimpleItem>
                <SimpleItem
                    dataField="resource_details.phone"
                    editorOptions={phoneEditorOptions}
                >
                  <Label text="Phone"/>
                  <RequiredRule message="Phone is required" />
                </SimpleItem>
                <SimpleItem
                    editorType="dxAutocomplete"
                    dataField="resource_details.address"
                    editorOptions={{
                      showDropDownButton: true,
                      activeStateEnabled: true,
                      valueExpr: 'description',
                      dataSource: predictionResults,
                      onInput(e) {
                        fetchPredictions(e.event.currentTarget.value, formRef);
                      }
                    }}
                >
                  <Label text="Address"/>
                </SimpleItem>
                 <SimpleItem
                    editorType="dxTextArea"
                    dataField="resource_details.notes"
                >
                  <Label text="Internal notes"/>
                </SimpleItem>
            </Form>

        </Popup>

      </React.Fragment>
    )
  } else {
    return (
      <div className="d-flex flex-row justify-content-center align-items-center mt-5 pt-5">
          <LoadIndicator
              id="large-indicator"
              height={60}
              width={60}
          />
      </div>
    )
  }
}
