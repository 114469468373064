import 'devextreme/dist/css/dx.common.css';
import './themes/generated/theme.base.css';
import './themes/generated/theme.additional.css';
import React, {useEffect} from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import './dx-styles.scss';
import LoadPanel from 'devextreme-react/load-panel';
import { NavigationProvider } from './contexts/navigation';
import { AuthProvider, useAuth } from './contexts/auth';
import { useScreenSizeClass } from './utils/media-query';
import Content from './Content';
import UnauthenticatedContent from './UnauthenticatedContent';
import { licenseKey } from './devextreme-license'
import config from 'devextreme/core/config'
import { APIProvider } from '@vis.gl/react-google-maps';


config({licenseKey});

function App() {
  const { user, loading } = useAuth();

  if (loading) {
    return <LoadPanel visible={true} />;
  }

  if (user) {
    return (

      <APIProvider apiKey={"AIzaSyAWqd53jTU9v4zHe9qExwb3L9uAN43zBdM"}>
        <Content />
      </APIProvider>
    )
  }

  return (
    <APIProvider apiKey={"AIzaSyAWqd53jTU9v4zHe9qExwb3L9uAN43zBdM"}>
      <UnauthenticatedContent />
    </APIProvider>
  ) 
}

export default function Root() {
  const screenSizeClass = useScreenSizeClass();

  return (
    <Router>
      <AuthProvider>
        <NavigationProvider>
          <div className={`app ${screenSizeClass}`}>
            <App />
          </div>
        </NavigationProvider>
      </AuthProvider>
    </Router>
  );
}
