import React from "react";
import { Button } from 'devextreme-react/button';
import './SubscriptionPlan.scss'
import {useNavigate} from 'react-router-dom'

export default function SubscriptionPlan(props) {
    const navigate = useNavigate();
    return (
      <div className="dx-card billing-card d-flex flex-column align-items-center h-100">
        {props.title && <h4 className="pt-5 dx-theme-accent-as-text-color">{props.title}</h4>}
        {props.subtitle && <h5 className="pt-5">{props.subtitle}</h5>}
        {props.price && <h5 className="pt-2"><strong className="dx-theme-accent-as-text-color">{props.price}</strong>{props.interval && "/"+props.interval}</h5>}
        {props.promo && <h6 className="pt-2"><div className="dx-theme-accent-as-text-color">{props.promo}</div></h6>}
        <ul className="pt-3 w-100 subscription-feature-list">
          {props.features}
        </ul>
        {props.userData && props.userData.subscription && (props.userData.subscription.plan_id === props.plan_id) &&  <div className="mt-5">
          {props.userData.subscription.plan_id==="old_system"?
            <div className="px-2">
              <p>Subscription ends on {(new Date(props.userData.subscription.current_period_end)).toLocaleDateString('en-us', { year:"numeric", month:"short", day:"numeric"})}</p>
            </div>:
            <div className="px-2" style={{textAlign: "center"}}>
            {props.userData.subscription.status==="trialing"?
              <p>Trial ends on {(new Date(props.userData.subscription.current_period_end)).toLocaleDateString('en-us', { year:"numeric", month:"short", day:"numeric"})}</p>:
              <p>Subscription {props.userData.subscription.canceled_at?"ends":"renews"} on {(new Date(props.userData.subscription.current_period_end)).toLocaleDateString('en-us', { year:"numeric", month:"short", day:"numeric"})}</p>
            }
            </div>}
        </div>}
        {props.userData ? <div className="mt-2 mb-1" >
          {props.userData.subscription?.plan_id === props.plan_id?
            <a href={props.cta_manage_link} target='_'>
              <Button
                className="px-5"
                type="default"
                stylingMode="text"
                onClick={()=>{
                  props.onManage(props.plan_id);
                }}>
                Manage plan
              </Button>
            </a>:
            <a href={(window.location.hostname==='localhost'?props.cta_buy_test_link:props.cta_buy_link)+"?client_reference_id="+props.userData.sub} target='_'>
              <Button
                className="px-5"
                type="default"
                text={props.cta_buy?props.cta_buy:"Upgrade"}
                size="lg"
                onClick={()=>{
                  props.onBuy(props.plan_id);
                }}
              />
            </a>
          }
        </div>:
        <div className="mt-2 mb-3" >
          <a href={props.cta_info_link} target='_'>
            <Button
              className=""
              type="default"
              stylingMode="text"
              onClick={()=>{
                navigate('/about/contact-us');
              }}>
              Learn more
            </Button>
          </a>
        </div>
      }
      </div>
    );
}
