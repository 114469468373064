import React from "react";
import { Button } from 'devextreme-react/button';
import './ScheduleDataTooltip.scss'
import {useNavigate} from 'react-router-dom'
import { renderServiceTitles, calculatePrices } from "../../utils/miscUtil";

export default function ScheduleDataTooltip(props) {
    const userData = null;
    const navigate = useNavigate();
    const serviceTitles = renderServiceTitles(props.data.appointmentData.services, props.data.appointmentData)
    const prices = calculatePrices(props.data.appointmentData.pricing)
    const cd = props.data.appointmentData.customer_data

    function prettyDate2(time) {
      var date = new Date(time);
      return date.toLocaleTimeString(navigator.language, {
        hour: '2-digit',
        minute:'2-digit'
      });
    }

  const renderPrices = (prices) => {
    if (prices && prices.quote) {
			return <>
				<p>Quoted price: <b>${prices.quote}</b></p>
				<p>Amount paid: <b>${prices.paid}</b></p>
				<p>Amount due: <b>${prices.due}</b>+tax</p>
			</>
    }
  }

  return (
    <>
      <div className="p-2 d-flex flex-column align-items-start justify-content-between schedule-data-tooltip">
        <h5>{serviceTitles ? serviceTitles : 'loading...'}</h5>

        <p className=""><i className="fas fa-clock pe-1"></i><b>{prettyDate2(props.data.appointmentData.startDate)} - {prettyDate2(props.data.appointmentData.endDate)}</b></p>
        {props.data.appointmentData.details && <p className="pb-2"><i className="fas fa-info-circle pe-1"></i> {props.data.appointmentData.details.notes}</p>}
        {cd && <p className="py-2">
          <i className="far fa-address-card pe-1"></i><b>{cd.name}</b> <span className="mx-2"> - </span> {cd.email} <span className="mx-2"> - </span> {cd.phone} {cd.address && <><span className="mx-2"> - </span>{cd.address}</>} {cd.notes && <><span className="mx-2"> - </span>{cd.notes}</>}
        </p>}
        {renderPrices(prices)}
        <div className="d-flex flex-row mt-2 gap-1">
          <div
            style={{
              backgroundColor: "#fff",
              backgroundImage: `url("${ props.data.appointmentData.provider?.resource_details.picture ? props.data.appointmentData.provider.resource_details.picture : '/DefaultAvatar.png'}")`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              width: "50px",
              height: "50px",
              borderRadius: "50%",
            }}
          />
          <div
            className="ms-1"
            style={{
              backgroundColor: "#fff",
              backgroundImage: `url("${ props.data.appointmentData.facility?.resource_details.picture ? props.data.appointmentData.facility.resource_details.picture : '/DefaultFacility.png'}")`,
              backgroundSize: 'cover',
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              width: "50px",
              height: "50px",
              borderRadius: "50%",
            }}
          />
        </div>
      </div>
    </>
  )
}
