import React, {useState, useEffect} from 'react';
import Toolbar, { Item } from 'devextreme-react/toolbar';
import Tabs from 'devextreme-react/tabs';
import Button from 'devextreme-react/button';
import UserPanel from '../user-panel/UserPanel';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom'
import {getUserDetails, getPublicServices, getResources, getAppointments} from '../../api/api'
import devices from 'devextreme/core/devices';
import './Header.scss';
import { Template } from 'devextreme-react/core/template';


export default function Header({ menuToggleEnabled, title, toggleMenu, user }) {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [servicesCount, setServicesCount] = useState(null)
  const [facilitiesCount, setFacilitiesCount] = useState(null)
  const [providersCount, setProvidersCount] = useState(null)
  const [appointmentsCount, setAppointmentsCount] = useState(null)
  const [userDetails, setUserDetails] = useState(null)
  const [selectedStep, setSelectedStep] = useState(null)

  const [setupTabs, setSetupTabs]  = useState([]);

  useEffect(() => {
    if (userDetails === null) {
      return
    }
    let tabs = [
      {
        id: 0,
        text: 'Profile',
        icon: (userDetails && userDetails.company_name)?'far fa-check-circle text-success':'far fa-circle',
        path: '/profile?setupMode=true&setupPopup=profile',
        done: (userDetails && userDetails.company_name)?true:false,
      },
      {
        id: 1,
        text: 'Facilities',
        icon: facilitiesCount?'far fa-check-circle text-success':'far fa-circle',
        path: '/facilities?setupMode=true&setupPopup=add',
        done: (facilitiesCount)?true:false,
      },
      {
        id: 2,
        text: 'Services',
        icon: servicesCount?'far fa-check-circle text-success':'far fa-circle',
        path: '/services?setupMode=true&setupPopup=add',
        done: (servicesCount)?true:false,
      },
      {
        id: 3,
        text: 'Team',
        icon: providersCount?'far fa-check-circle text-success':'far fa-circle',
        path: '/providers?setupMode=true&setupPopup=add',
        done: (providersCount)?true:false,
      },
      {
        id: 4,
        text: 'Settings',
        icon: (userDetails && Object.keys(userDetails.scheduling_settings).length > 1 )?'far fa-check-circle text-success':'far fa-circle',
        path: '/profile?setupMode=true&setupPopup=settings',
        done: (userDetails && Object.keys(userDetails.scheduling_settings).length > 1)?true:false,
      },
      {
        id: 5,
        text: 'First Appointment',
        icon: appointmentsCount?'far fa-check-circle text-success':'far fa-circle',
        path: '/schedule?setupMode=true',
        done: (appointmentsCount)?true:false,
      },
    ];
    console.log("setupStep", searchParams.get('setupStep'));
    if (searchParams.get('setupMode')) {
      setSetupTabs(tabs)
    }
    if (searchParams.get('setupStep') === null) {
      for (let i=0; i<tabs.length; i++){
        if (tabs[i].done === false) {
          console.log("navigating to:",tabs[i].path+"&setupStep="+tabs[i].id);
          setSelectedStep(tabs[i].id);
          navigate(tabs[i].path);
          return
        }
      }
    }
    if ((searchParams.get('setupMode')) && (searchParams.get('setupStep') === null)) {
      navigate("/home")
    }
  }, [userDetails, servicesCount, facilitiesCount, providersCount, searchParams, appointmentsCount]);


  useEffect(() => {
    console.log("setupMode:", searchParams.get('setupMode'));
    if (user && user.signInUserSession) {
      getUserDetails(user, data => {setUserDetails(data)}, err => console.log(err));
      getAppointments(user, data => {setAppointmentsCount(data.length); console.log(data.length);}, err => console.log(err));
      getPublicServices(user.username, data => {setServicesCount(data.services.length)}, err => console.log((err)))
      getResources(user, data => {
        let facilities_count = 0
        let provider_count = 0
        for (var i=0; i<data.length; i++){
          if (data[i].resource_type === "facility") {
            facilities_count = facilities_count + 1
          }
          if (data[i].resource_type === "provider") {
            provider_count = provider_count + 1
          }
        }
        setFacilitiesCount(facilities_count);
        setProvidersCount(provider_count);
      }, err => console.log(err));
    }
  }, [user, searchParams]);

  const onTabClicked = (e) => {
    setSelectedStep(e.itemData.id);
    navigate(e.itemData.path+"&setupStep="+e.itemData.id);
  }

  return (
    <header className={'header-component'}>
      <Toolbar className={'header-toolbar'}>
        <Item
          visible={menuToggleEnabled}
          location={'before'}
          widget={'dxButton'}
          cssClass={'menu-button'}
        >
          <Button icon="menu" stylingMode="text" onClick={toggleMenu} />
        </Item>
        <Item
          location={'before'}
          cssClass={'header-title'}
          text={title}
          visible={!!title}
        />
        {user ? <Item
          location={'after'}
          locateInMenu={'auto'}
          menuItemTemplate={'userPanelTemplate'}
        >
          <Button
            className={'user-button authorization'}
            width={210}
            height={'100%'}
            stylingMode={'text'}
          >
            <UserPanel menuMode={'context'} />
          </Button>
        </Item>:
        <Item
          location={'after'}
          locateInMenu={'auto'}
        >
          <Button
            height={'100%'}
            stylingMode={'text'}
            type="default"
            text={devices.current().deviceType === 'phone'?"Sign Up":"Create Account | Sign In"}
            onClick={() => {navigate('/login')}}
          />

        </Item>
        }
        <Template name={'userPanelTemplate'}>
          <UserPanel menuMode={'list'} />
        </Template>
      </Toolbar>
      {searchParams.get('setupMode') && <div>
        <Tabs
            id="withIcon"
            width="100%"
            dataSource={setupTabs}
            scrollByContent={false}
            selectionMode="single"
            selectedIndex={selectedStep}
            onItemClick={onTabClicked}
            showNavButtons={true}
            orientation="horizontal"
            stylingMode="secondary"
            iconPosition="top"
          />
      </div>}
    </header>
)}
