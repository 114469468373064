import React, { useEffect, useCallback, useState } from 'react';
import { useSearchParams } from 'react-router-dom'
import Tasks from '../../components/tasks/Tasks'
import { useAuth } from '../../contexts/auth';
import {getUserDetails, getPublicServices, getResources} from '../../api/api'
import { useNavigate } from 'react-router-dom'
import notify from 'devextreme/ui/notify';
import './home.scss';

export default function Home() {
  const { user, refreshSession } = useAuth();
  const navigate = useNavigate()
  const [services, setServices] = useState(null)
  const [setupMode, setSetupMode] = useState(false)
  const [resources, setResources] = useState(null)
  const [userDetails, setUserDetails] = useState(null)

  useEffect(() => {
    if (user.signInUserSession) {
      getUserDetails(user, onGetUserDetailsSuccess, onError);
      getPublicServices(user.username, data => {setServices(data.services)}, err => console.log((err)))
      getResources(user, onGetResourcesSuccess, err => console.log(err));
    }
  }, [user && user.signInUserSession, user.signInUserSession.idToken.payload]);

  useEffect(() => {
    if (resources === null) {
      return
    }
    if (userDetails === null) {
      return
    }

    if (userDetails.credit <= 0 ) {
      navigate('/billing')
      notify("Please purchase credit to continue.", 'error', 2000);
      return
    }
    if (userDetails.credit < 10) {
      notify("Your credit is running low. Please purchase additional credit avoid service interruptions.", 'warning', 5000);
    }

  }, [resources, userDetails]);

  const onError = useCallback((error) => {
    if (error.status === 401) {
      refreshSession();
    }
    else {
      notify("An error has occured. Please try again later.", 'error', 2000);
      console.log(error);
    }
  }, [refreshSession]);

  const onGetResourcesSuccess = (r) => {
    setResources(r);

  }

  const onGetUserDetailsSuccess = useCallback((data) => {
    setUserDetails(data);
  }, [])


  return <Tasks services={services}/>

}
