import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Button } from 'devextreme-react/button';
import { Popup, Position, ToolbarItem } from 'devextreme-react/popup';
import './FeatureBox.scss'
import devices from 'devextreme/core/devices';

const isPhone = devices.current().deviceType === 'phone';

export default function FeatureBox(props) {
  const [showPopup, setShowPopup] = useState(props.show_video);
  const videoRef = useRef(null);

  const onClosePopup = () => {
    if (videoRef && videoRef.current) {
      videoRef.current.pause();
      videoRef.current.currentTime = 0;
    }
    setShowPopup(false);
  }

  const onClick = () => {
    if (videoRef && videoRef.current) {
      videoRef.current.play();
    }
    setShowPopup(true);
  }

  const [cancelButtonOptions, setCancelButtonOptions] = useState({
    disabled: false,
    text: 'Back',
    width: '100%',
    onClick: onClosePopup,
  });

    return (
      <>
      <div id={props.main_id} onClick={onClick} className="feature-box-block">
        {props.icon && <div className="feature-box">
          {props.icon}
        </div>}
        {props.slides && props.slides.length === 1 &&
          <div className="feature-box-image-container">
            <div className="feature-box-image" style={{backgroundImage: `url(${props.slides[0]})` }}>
            </div>
            <div className="feature-box-image-overlay">
            </div>
          </div>
        }
        {props.slides && props.slides.length > 1 &&
        <div id={"carousel"+props.main_id} className="carousel slide carousel-fade" data-bs-ride="carousel">
          <div className="carousel-inner">
            { props.slides.map( (slide, idx) =>
              <div key={"slide-"+slide} className={idx===0?"carousel-item active":"carousel-item"} style={{textAlign: "center"}}>
                <img src={slide} style={{maxHeight:"300px", height: "300px", maxWidth:"100%"}} alt="..."/>
              </div>
            ) }
          </div>
          <button className="carousel-control-prev text-dark" type="button" data-bs-target={"#"+"carousel"+props.main_id} data-bs-slide="prev">
            <i className="fas fa-chevron-left fa-3x"></i>
            <span className="visually-hidden">Previous</span>
          </button>
          <button className="carousel-control-next text-dark" type="button" data-bs-target={"#"+"carousel"+props.main_id} data-bs-slide="next">
            <i className="fas fa-chevron-right fa-3x"></i>
            <span className="visually-hidden">Next</span>
          </button>
        </div>
        }
        <div className="feature-box-content text-center">
          <h4>{props.title}</h4>
          <p className="marketing-list">{props.description}</p>
          <ul className="pt-3 w-100 text-start marketing-list">
            {props.features}
          </ul>
          {props.cta_label && <Button href={props.cta_link} size="lg" variant="outline-primary" className="mt-5">{props.cta_label}</Button>}
        </div>
      </div>
      <Popup
        className="text-danger"
        visible={showPopup}
        onHidden={onClosePopup}
        dragEnabled={false}
        showCloseButton={true}
        fullScreen={false}
        showTitle={true}
        title={props.title}
        container=".dx-viewport"
        width={devices.current().deviceType === 'phone'?"90vw":"auto"}
        maxWidth="90vw"
        height={devices.current().deviceType === 'phone'?"80vw":"auto"}
        maxHeight="80vh"
      >
        <ToolbarItem
          widget="dxButton"
          toolbar="bottom"
          location="after"
          options={cancelButtonOptions}
        />
        {devices.current().deviceType === 'phone'?
          <div className="w-100">
            <video ref={videoRef} controls preload="none" className="ratio ratio-16x9">
             <source src={props.video_src} type="video/mp4"/>
            </video>
          </div>:
          <div className="w-100" style={{maxWidth:"1000px"}}>
            <video ref={videoRef} controls preload="none" className="ratio ratio-16x9">
             <source src={props.video_src} type="video/mp4"/>
            </video>
          </div>
        }
      </Popup>
      </>
    );
}
