import React, { useState, useCallback, useRef, useEffect } from 'react';
import Drawer from 'devextreme-react/drawer';
import appInfo from '../../app-info';
import ScrollView from 'devextreme-react/scroll-view';
import { Header, SideNavigationMenu, Footer, Hero, IconBox,
  Title, ContactForm, SubscriptionPlan, FeatureBox } from '../../components';
import {useNavigate, useParams} from 'react-router-dom'
import { useScreenSize } from '../../utils/media-query';
import { Template } from 'devextreme-react/core/template';
import { useMenuPatch } from '../../utils/patches';
import PP from '../../pages/legal/pp'
import TOS from '../../pages/legal/tos'


export default function Terms({ title, description, children }) {
  const scrollViewRef = useRef(null);
  const navigate = useNavigate();

  let { id } = useParams();
  const { isXSmall, isLarge } = useScreenSize();
  const [patchCssClass, onMenuReady] = useMenuPatch();
  const [menuStatus, setMenuStatus] = useState(MenuStatus.Closed);


  const toggleMenu = useCallback(({ event }) => {
    setMenuStatus(
      prevMenuStatus => prevMenuStatus === MenuStatus.Closed
        ? MenuStatus.Opened
        : MenuStatus.Closed
    );
    event.stopPropagation();
  }, []);

  const temporaryOpenMenu = useCallback(() => {
    setMenuStatus(
      prevMenuStatus => prevMenuStatus === MenuStatus.Closed
        ? MenuStatus.TemporaryOpened
        : prevMenuStatus
    );
  }, []);

  const onOutsideClick = useCallback(() => {
    setMenuStatus(
      prevMenuStatus => prevMenuStatus !== MenuStatus.Closed && !isLarge
        ? MenuStatus.Closed
        : prevMenuStatus
    );
    return menuStatus === MenuStatus.Closed ? true : false;
  }, [isLarge, menuStatus]);

  const onNavigationChanged = useCallback(({ itemData, event, node }) => {
    if (menuStatus === MenuStatus.Closed || !itemData.path || node.selected) {
      event.preventDefault();
      return;
    }

    if (itemData.external) {
      window.open(itemData.path, '_blank').focus();
    } else {
      navigate(itemData.path);
    }

    if (!isLarge || menuStatus === MenuStatus.TemporaryOpened) {
      setMenuStatus(MenuStatus.Closed);
      event.stopPropagation();
    }
  }, [navigate, menuStatus, isLarge]);


  const renderPage = id => {
    if(id == 'pp') {
      return <PP/>
    }
    if (id == 'tos') {
      return <TOS/>
    }
  }

  return (
    <div className={'side-nav-outer-toolbar'}>
      <Header
        menuToggleEnabled
        toggleMenu={toggleMenu}
        title={title}
      />
      <Drawer
        className={['drawer', patchCssClass].join(' ')}
        position={'before'}
        closeOnOutsideClick={onOutsideClick}
        openedStateMode={isLarge ? 'shrink' : 'overlap'}
        revealMode={isXSmall ? 'slide' : 'expand'}
        minSize={isXSmall ? 0 : 0}
        maxSize={250}
        shading={isLarge ? false : true}
        opened={menuStatus === MenuStatus.Closed ? false : true}
        template={'menu'}
      >
        <div className={'container px-1'} style={{maxWidth: "100%"}}>
          <ScrollView ref={scrollViewRef} className={'layout-body with-footer'} >
            
            <div className="mx-auto" style={{maxWidth: '1296px'}}>
            { renderPage(id) }

              <Footer>
                Copyright © 2021-{new Date().getFullYear()} {appInfo.company}.
                <br />
                All trademarks or registered trademarks are property of their
                respective owners.
                <br />
                Version {appInfo.version}
              </Footer>
            </div>
          </ScrollView>
        </div>
        <Template name={'menu'}>
          <SideNavigationMenu
            compactMode={menuStatus === MenuStatus.Closed}
            selectedItemChanged={onNavigationChanged}
            openMenu={temporaryOpenMenu}
            onMenuReady={onMenuReady}
          >
          </SideNavigationMenu>
        </Template>
      </Drawer>
    </div>
  );

}

const MenuStatus = {
  Closed: 1,
  Opened: 2,
  TemporaryOpened: 3
};
