import {getUser} from './auth'

export const getApiUrl = () => {
  const apiId = 'm0y5k2f7ug'
  return `https://${apiId}.execute-api.us-east-2.amazonaws.com/Prod`
};

export const getUserDetails = async (user, onSuccess, onError, retries=3) => {
  try {
    let q = '/user'
    const response = await fetch(getApiUrl()+q, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': user.signInUserSession.idToken.jwtToken,
      }
    });
    const r = await response.json();
    if (response.ok) {
      r.user_details.sub = user.signInUserSession.idToken.payload.sub;
      r.user_details.name = user.signInUserSession.idToken.payload.name;
      r.user_details.email = user.signInUserSession.idToken.payload.email;
      r.user_details.phone = user.signInUserSession.idToken.payload.phone_number;
      r.user_details.image = user.signInUserSession.idToken.payload.picture;

      r.user_details.scheduling_settings = (!r.user_details.scheduling_settings)?{}:r.user_details.scheduling_settings;
      //These 2 settings must be completed by the user.
      //r.user_details.scheduling_settings.base_address = (!r.user_details.scheduling_settings.base_address)?null:r.user_details.scheduling_settings.base_address;
      //r.user_details.scheduling_settings.cancelation_policy_text = (!r.user_details.scheduling_settings.cancelation_policy_text)?null:r.user_details.scheduling_settings.cancelation_policy_text;
      // r.user_details.scheduling_settings.traffic_model = (!r.user_details.scheduling_settings.traffic_model)?"pessimistic":r.user_details.scheduling_settings.traffic_model;
      // r.user_details.scheduling_settings.max_service_radius = (!r.user_details.scheduling_settings.max_service_radius)?100:r.user_details.scheduling_settings.max_service_radius;
      // r.user_details.scheduling_settings.route_buffer_percentage = (!r.user_details.scheduling_settings.route_buffer_percentage)?10:r.user_details.scheduling_settings.route_buffer_percentage;
      // r.user_details.scheduling_settings.route_buffer_min_time = (!r.user_details.scheduling_settings.route_buffer_min_time)?1:r.user_details.scheduling_settings.route_buffer_min_time;
      // r.user_details.scheduling_settings.route_buffer_max_time = (!r.user_details.scheduling_settings.route_buffer_max_time)?10:r.user_details.scheduling_settings.route_buffer_max_time;
      // r.user_details.scheduling_settings.passenger_load_time = (!r.user_details.scheduling_settings.passenger_load_time)?10:r.user_details.scheduling_settings.passenger_load_time;
      // r.user_details.scheduling_settings.passenger_unload_time = (!r.user_details.scheduling_settings.passenger_unload_time)?5:r.user_details.scheduling_settings.passenger_unload_time;
      // r.user_details.scheduling_settings.max_pick_up_wait_time = (!r.user_details.scheduling_settings.max_pick_up_wait_time)?60:r.user_details.scheduling_settings.max_pick_up_wait_time;
      r.user_details.scheduling_settings.timezone = (!r.user_details.scheduling_settings.timezone)?Intl.DateTimeFormat().resolvedOptions().timeZone:r.user_details.scheduling_settings.timezone;
      onSuccess(r.user_details);
    } else {

      onError(response);
    }
  } catch (error) {
    console.log("error",error);
    onError(error);
  } finally {
  }
}

export const updateUserDetails = async (user, data, onSuccess, onError, retries=3) => {
  try {
    let q = '/user'
    const response = await fetch(getApiUrl()+q, {
      method: 'PUT',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': user.signInUserSession.idToken.jwtToken,
      },
      body: JSON.stringify(data)
    });
    const r = await response.json();
    if (response.ok) {
      r.user_details.sub = user.signInUserSession.idToken.payload.sub;
      r.user_details.name = user.signInUserSession.idToken.payload.name;
      r.user_details.email = user.signInUserSession.idToken.payload.email;
      r.user_details.phone = user.signInUserSession.idToken.payload.phone_number;
      r.user_details.image = user.signInUserSession.idToken.payload.picture;
      onSuccess(r.user_details);
    } else {
      onError(response);
    }
  } catch (error) {
    onError(error);
  } finally {
  }
}

export const getProfile = async (id, onSuccess, onError, retries=3) => {
  try {
    let q = '/public/profile/'+id
    const response = await fetch(getApiUrl()+q, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      }
    });
    const r = await response.json();
    if (response.ok) {
      onSuccess(r.profile);
    } else {
      onError(response);
    }
  } catch (error) {
    onError(error);
  } finally {
  }
}

export const getResources = async (user, onSuccess, onError, retries=3) => {
  try {
    let q = '/resource'
    const response = await fetch(getApiUrl()+q, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': user.signInUserSession.idToken.jwtToken,
      }
    });
    const r = await response.json();
    if (response.ok) {
      onSuccess(r.resources);
    } else {
      onError(response);
    }
  } catch (error) {
    onError(error);
  } finally {
  }
}

export const updateResource = async (user, data, onSuccess, onError, retries=3) => {
  try {
    let q = '/resource'
    const response = await fetch(getApiUrl()+q, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': user.signInUserSession.idToken.jwtToken,
      },
      body: JSON.stringify(data)
    });
    const r = await response.json();
    if (response.ok) {
      onSuccess(r.resource);
    } else {
      onError(response);
    }
  } catch (error) {
    onError(error);
  } finally {
  }
}

export const deleteResource = async (user, data, onSuccess, onError, retries=3) => {
  try {
    let q = '/resource'
    const response = await fetch(getApiUrl()+q, {
      method: 'DELETE',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': user.signInUserSession.idToken.jwtToken,
      },
      body: JSON.stringify(data)
    });
    const r = await response.json();
    if (response.ok) {
      onSuccess(r.resource);
    } else {
      onError(response);
    }
  } catch (error) {
    onError(error);
  } finally {
  }
}

export const getPublicAppointmentById = async (id, appointmentId, onSuccess, onError, retries=3) => {
  try {
    let q = '/public/profile/'+id+'/appointment/'+appointmentId
    const response = await fetch(getApiUrl()+q, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    });
    const r = await response.json();
    if (response.ok) {
      onSuccess(r.appointment);
    } else {
      onError(response);
    }
  } catch (error) {
    onError(error);
  }
}

export const updatePublicAppointmentById = async (id, appointmentId, data, onSuccess, onError, retries=3) => {
  try {
    let q = '/public/profile/'+id+'/appointment/'+appointmentId
    let d = Object.assign({}, data);
    delete d['date'];

    const response = await fetch(getApiUrl()+q, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(d)
    });
    const r = await response.json();
    if (response.ok) {
      onSuccess(r.resource);
    } else {
      onError(response);
    }
  } catch (error) {
    onError(error);
  }
}

export const getPricesForAppointment = async (id, appointment, onSuccess, onError) => {
  try {
    let q = '/public/profile/'+id+'/prices'
    appointment.owner_id = id
    const response = await fetch(getApiUrl()+q, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(appointment)
    });
    const r = await response.json();
    if (response.ok) {
      onSuccess(r.prices);
    } else {
      onError(response);
    }
  } catch (error) {
    onError(error);
  }
}


export const getAvailableResources = async (user, appointment, onSuccess, onError) => {
  try {
    appointment['owner_id'] = user.username
    let q = '/resource/available'
    const response = await fetch(getApiUrl()+q, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': user.signInUserSession.idToken.jwtToken
      },
      body: JSON.stringify(appointment)
    });
    const r = await response.json();
    if (response.ok) {
      onSuccess(r);
    } else {
      onError(response);
    }
  } catch (error) {
    onError(error);
  }
}

export const getAppointments = async (user, onSuccess, onError, retries=3) => {
  try {
    let q = '/appointment'
    const response = await fetch(getApiUrl()+q, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': user.signInUserSession.idToken.jwtToken,
      }
    });
    const r = await response.json();
    if (response.ok) {
      onSuccess(r.appointments);
    } else {
      onError(response);
    }
  } catch (error) {
    onError(error);
  } finally {
  }
}

export const getAppointment = async (user, appointment_id, onSuccess, onError, retries=3) => {
  try {
    let q = '/appointment/'+appointment_id
    const response = await fetch(getApiUrl()+q, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': user.signInUserSession.idToken.jwtToken,
      }
    });
    const r = await response.json();
    if (response.ok) {
      onSuccess(r.appointment);
    } else {
      onError(response);
    }
  } catch (error) {
    onError(error);
  }
}

export const updateAppointment = async (user, data, onSuccess, onError, retries=3) => {
  try {
    let q = '/appointment'
    let d = Object.assign({}, data);
    delete d['provider'];
    delete d['facility'];
    delete d['allDay']
    delete d['services']
    //delete d['customer_data']

    const response = await fetch(getApiUrl()+q, {
      method: 'PUT',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': user.signInUserSession.idToken.jwtToken,
      },
      body: JSON.stringify(d)
    });
    const r = await response.json();
    if (response.ok) {
      onSuccess(r.resource);
    } else {
      onError(response);
    }
  } catch (error) {
    onError(error);
  } finally {
  }
}

export const inviteDriversToAppointment = async (user, appointment_id, onSuccess, onError, retries=3) => {
  try {
    let q = '/appointment/'+appointment_id+"/invite-drivers"

    const response = await fetch(getApiUrl()+q, {
      method: 'PUT',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': user.signInUserSession.idToken.jwtToken,
      },
      body: JSON.stringify({})
    });
    const r = await response.json();
    if (response.ok) {
      onSuccess({});
    } else {
      onError(response);
    }
  } catch (error) {
    onError(error);
  } finally {
  }
}

export const deleteAppointment = async (user, data, onSuccess, onError, retries=3) => {
  try {
    let q = '/appointment'
    const response = await fetch(getApiUrl()+q, {
      method: 'DELETE',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': user.signInUserSession.idToken.jwtToken,
      },
      body: JSON.stringify(data)
    });
    const r = await response.json();
    if (response.ok) {
      onSuccess(r.appointment);
    } else {
      onError(response);
    }
  } catch (error) {
    onError(error);
  } finally {
  }
}

export const getPrices = async (user, onSuccess, onError, retries=3) => {
  try {
    let q = '/service'
    const response = await fetch(getApiUrl()+q, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': user.signInUserSession.idToken.jwtToken,
      }
    });
    const r = await response.json();
    if (response.ok) {
      onSuccess(r);
    } else {
      onError(response);
    }
  } catch (error) {
    onError(error);
  } finally {
  }
}

export const updatePrice = async (user, data, onSuccess, onError, retries=3) => {
  try {
    let q = '/service'
    const response = await fetch(getApiUrl()+q, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': user.signInUserSession.idToken.jwtToken,
      },
      body: JSON.stringify(data)
    });
    const r = await response.json();
    if (response.ok) {
      onSuccess(r.price);
    } else {
      onError(response);
    }
  } catch (error) {
    onError(error);
  } finally {
  }
}

export const deletePrice = async (user, data, onSuccess, onError, retries=3) => {
  try {
    let q = '/service'
    const response = await fetch(getApiUrl()+q, {
      method: 'DELETE',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': user.signInUserSession.idToken.jwtToken,
      },
      body: JSON.stringify(data)
    });
    const r = await response.json();
    if (response.ok) {
      onSuccess(r);
    } else {
      onError(response);
    }
  } catch (error) {
    onError(error);
  } finally {
  }
}


export const getPublicServices = async (owner_id, onSuccess, onError, retries=3) => {
  try {
    let q = `/public/profile/${owner_id}/services`
    const response = await fetch(getApiUrl()+q, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      }
    });
    const r = await response.json();
    if (response.ok) {
      onSuccess(r);
    } else {
      onError(response);
    }
  } catch (error) {
    onError(error);
  } finally {
  }
}

export const getAvailableTimes = async (owner_id, data, onSuccess, onError) => {
  try {
    let q = `/public/profile/${owner_id}/availability`
    const response = await fetch(getApiUrl()+q, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    });
    const r = await response.json();
    if (response.ok) {
      onSuccess(r.availability);
    } else {
      onError(response);
    }
  } catch (error) {
    onError(error);
  }
}

export const getPublicServiceProviders = async (owner_id, onSuccess, onError, retries=3) => {
  try {
    let q = `/public/profile/${owner_id}/providers`
    const response = await fetch(getApiUrl()+q, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      }
    });
    const r = await response.json();
    if (response.ok) {
      onSuccess(r);
    } else {
      onError(response);
    }
  } catch (error) {
    onError(error);
  } finally {
  }
}

export const getPublicFacilities = async (owner_id, onSuccess, onError, retries=3) => {
  try {
    let q = `/public/profile/${owner_id}/facilities`
    const response = await fetch(getApiUrl()+q, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      }
    });
    const r = await response.json();
    if (response.ok) {
      onSuccess(r);
    } else {
      onError(response);
    }
  } catch (error) {
    onError(error);
  } finally {
  }
}


const dateToLocalTZ = (data) => {
  const dateOffset = (new Date()).getTimezoneOffset();
  var dsd = new Date(data);
  dsd.setTime(dsd.getTime() + dateOffset * 60000);
  return dsd;
}

export const appointmentStatusValues = [
  {
    text: 'Submitted',
    id: 'pending',
    owner_allowed: true,
    customer_allowed: true,
    requires_vehicle: false,
    requires_existing_appointment: false,
  },
  {
    text: 'Available',
    id: 'available',
    owner_allowed: true,
    customer_allowed: false,
    requires_vehicle: false,
    requires_existing_appointment: false,
  },
  {
    text: 'Rejected',
    id: 'rejected',
    owner_allowed: true,
    customer_allowed: false,
    requires_vehicle: false,
    requires_existing_appointment: true,
  },
  {
    text: 'Canceled',
    id: 'canceled',
    owner_allowed: false,
    customer_allowed: true,
    requires_vehicle: false,
    requires_existing_appointment: true,
  },
  {
    text: 'Confirmed - Paid',
    id: 'payed',
    owner_allowed: true,
    customer_allowed: false,
    requires_vehicle: false,
    requires_existing_appointment: false,
  },
  {
    text: 'Confirmed - Unpaid',
    id: 'unpayed',
    owner_allowed: true,
    customer_allowed: false,
    requires_vehicle: false,
    requires_existing_appointment: false,
  },
  {
    text: 'Completed',
    id: 'completed',
    owner_allowed: true,
    customer_allowed: true,
    requires_vehicle: true,
    requires_existing_appointment: true,
  },
];

export const schedulerGroups = [
  {
    ID: 1,
    Name: 'Facility',
    Value: false
  },
  {
    ID: 2,
    Name: 'Team Member',
    Value: true
  }
]

export const weekDays = [
  {
    ID: 1,
    Name: 'Mon',
    Value: 'Monday'
  },
  {
    ID: 2,
    Name: 'Tue',
    Value: 'Tuesday'
  },
  {
    ID: 3,
    Name: 'Wed',
    Value: 'Wednesday'
  },
  {
    ID: 4,
    Name: 'Thu',
    Value: 'Thursday'
  },
  {
    ID: 5,
    Name: 'Fri',
    Value: 'Friday'
  },
  {
    ID: 6,
    Name: 'Sat',
    Value: 'Saturday'
  },
  {
    ID: 7,
    Name: 'Sun',
    Value: 'Sunday'
  }
]
