import React, {useState, useEffect} from "react";
import './UsageChart.scss'
import {
  Chart,
  Series,
  ArgumentAxis,
  Legend,
  Margin,
  Tooltip,
  Label,
  Format,
  Title,
  Font,
  CommonSeriesSettings,
  CommonAxisSettings,
} from 'devextreme-react/chart';

export default function UsageChart(props) {
    const [usageData, setUsageData] = useState(null);
    useEffect(() => {
      if (props.data) {
        //console.log(props.data);
        let data = []
        for (const [key, value] of Object.entries(props.data)) {
          let dt = key.split("-")
          dt = new Date(dt[0],dt[1]-1);
          const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
          let dt_str = months[dt.getMonth()] + " " + dt.getFullYear()
          data.push({month: dt, month_str:dt_str, appointments: value['appointments']})
        }
        console.log(data);
        setUsageData(data);
      }
    }, [props.data]);

    const customizeXAxisLabel = (data) => {
      console.log(data);
      return("test")
    }
    return (
        <>
        <Chart

          palette="Violet"
          dataSource={usageData}
        >
          <Title text="Appointments Created">
            <Font color="black" />
          </Title>
          <CommonSeriesSettings argumentField="month_str" />
          <Series
            valueField="appointments"
            type="bar"
          />

          <Margin bottom={20} />

          <Legend
            visible={false}
          />
          <Tooltip enabled={true} />
        </Chart>
        </>
    );
};
