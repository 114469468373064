import React, { useState, useEffect, useCallback, useRef } from 'react';
import './Billing.scss';
import { useAuth } from '../../contexts/auth';
import {getUserDetails} from '../../api/api'
import { LoadIndicator } from 'devextreme-react/load-indicator';
import notify from 'devextreme/ui/notify';
import { Popup, Position, ToolbarItem } from 'devextreme-react/popup';
import { SubscriptionPlan, UsageChart } from '../../components';


export default function Billing() {
  const { user, refreshSession } = useAuth();
  const [userData, setUserData] = useState(null);
  const [working, setWorking] = useState(null);
  const [currentCredit, setCurrentCredit] = useState(null);

  const onError = useCallback((error) => {
    if (error.status === 401) {
      refreshSession();
    } else {
      console.log(error);
    }
    notify("We ran into an error. Please try again.", 'error', 3000);
    setWorking(null);
  }, [refreshSession]);

  const onGetUserDetailsSuccess = useCallback((data) => {
    if (userData === null) {
      setCurrentCredit(data.credit)
    }
    setUserData(data)

  }, [userData, currentCredit])

  const onCloseDialog = () => {
    setWorking(null);
  }

  const onPlanSubscribe = (plan) => {
    setWorking("checkout");
  }

  useEffect(() => {
    if (working === "checkout") {
      if ((userData.credit !== currentCredit) ) {
        setWorking(null)
        setCurrentCredit(userData.credit)
      } else {
        setTimeout(function(){
          getUserDetails(user, onGetUserDetailsSuccess, onError);
        }, 2000);
      }
    }
    if (working === "manage") {
      setTimeout(function(){
        getUserDetails(user, onGetUserDetailsSuccess, onError);
      }, 2000);
    }
  }, [working, userData, currentCredit]);

  useEffect(() => {
    if (user&&user.signInUserSession) {
      getUserDetails(user, onGetUserDetailsSuccess, onError);
    }
  }, [user, user&&user.signInUserSession]);


  return (
    <React.Fragment>
      <h2 className={'content-block'}>Usage</h2>
      { userData && userData.usage && (working===null) && <div className="row justify-content-center">
        <div className="col-12 pb-2">
          <UsageChart
            data={userData.usage}
          />
        </div>
      </div>}
      <h2 className={'content-block'}>Credit</h2>
      { userData && (working===null) && <div className="row justify-content-center">
        <div className="col-12 col-sm-6 col-lg-3 pb-2">
          <div className="dx-card billing-card d-flex flex-column align-items-center h-100">
            <h5 className="pt-5">Available Credit</h5>
            <h4 className="pt-5 dx-theme-accent-as-text-color">${userData.credit}</h4>
          </div>
        </div>
        <div className="col-12 col-sm-6 col-lg-3 pb-2">
          <SubscriptionPlan
            subtitle="Add Credit"
            price="$20"
            plan_id="price_1P1dS7DEcG27YvSO9Uuv76bM"
            userData={userData}
            features={<p>Change quantity at checkout for higher amount</p> }
            onBuy={onPlanSubscribe}
            cta_buy="Buy"
            cta_buy_link="https://buy.stripe.com/aEU5lZ7bv6PZ2JObIK"
            cta_buy_test_link="https://buy.stripe.com/test_4gw2aPf9xe4b716009"
          />
        </div>

      </div>}

      <Popup
        className="text-danger"
        visible={working !== null}
        dragEnabled={false}
        showCloseButton={true}
        showTitle={true}
        onHidden={onCloseDialog}
        title="Checking..."
        container=".dx-viewport"
        width={300}
        height={300}
      >
        <Position at="center" my="center"collision="fit" />
        <div className="text-center">
          <p>Checking your credit status...</p>
          <LoadIndicator
            visible={true}
            height={40}
            width={40}
          />
        </div>
      </Popup>




    </React.Fragment>
)}
