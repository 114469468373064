import React, { useEffect, useState } from 'react';
import ScrollView from 'devextreme-react/scroll-view';
import {useNavigate, useParams} from 'react-router-dom'
import {getProfile} from '../../api/api'
import { LoadIndicator } from 'devextreme-react/load-indicator';
import { BookService } from '../../components';
import './single-card-book.scss';


export default function SingleCardBook({ title, logo, description, children }) {
  let { id } = useParams();
  const [profileData, setProfileData] = useState(null);

  const onError = (error) => {
    console.log(error);
  };

  const onSuccess = (data) => {
    console.log(data);
    setProfileData(data);
  };

  useEffect(() => {
    if (id) {
      getProfile(id, onSuccess, onError);
    }
  }, [id]);

  return (
    <ScrollView height={'100%'} width={'100%'} className={'with-footer single-card-book'}>
      {profileData ? <div className={'dx-card content d-flex flex-column justify-content-start'}>
          <BookService profileData={profileData}/>
      </div>:
      <div className="dx-card content d-flex flex-row justify-content-center align-items-center mt-5 pt-5">
        <LoadIndicator
            id="large-indicator"
            height={60}
            width={60}
          />
          </div>
    }
    </ScrollView>
)}
