import { useCallback } from "react";
import './ScheduleResourceCell.scss'



export default function ScheduleResourceCell(props) {

    const data = props.data.data
    let image = data.resource_details?.picture
    let defaultFacilityImg = "DefaultFacility.png"
    if (data.resource_id == '00000000-0000-0000-0000-000000000001') {
      defaultFacilityImg = "DefaultFacility2.png"
    }

    const renderResourceCell = useCallback( () => {
      if(data.resource_type === "facility") {
        return <>
          { image ?
            <div
              className="facilityGroupImage"
              style={{
                backgroundImage: "url(" + image + ")"
              }}
            >
            </div>
            :
             <div
              className="facilityGroupImage"
              style={{
                backgroundImage: `url(${defaultFacilityImg})`
              }}
            >
            </div>
          }
          <p className="m-0 w-100 resource-cell-name" style={{textWrap: 'warp'}} >{data.resource_details.name}</p>
        </>
      }
      if(data.resource_type === "provider") {
        return <>
          { image ?
            <div
              className="providerGroupImage"
              style={{
                backgroundImage: "url(" + image + ")"
              }}
            >
            </div>
            :
            <i className="fas fa-exclamation-triangle fs-3 mt-3 mb-2 opacity-50 text-warning"></i>
            }
          <p className="m-0 w-100 resource-cell-name" style={{textWrap: 'warp'}} >{data.resource_details.name}</p>
        </>
      }
    }, [props])


    return (
      <div className="resource-cell d-flex flex-column align-items-center justify-content-center w-100">
        {renderResourceCell()}
      </div>
    )
}
