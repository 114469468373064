import { useState, useEffect, useRef, useCallback } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useNavigate } from 'react-router';
import { LoadIndicator } from 'devextreme-react/load-indicator';
import {
    DataGrid, Column, Editing, Button as DGButton,
} from 'devextreme-react/data-grid';
import { IPopoverOptions, Popover } from 'devextreme-react/popover';
import { Tooltip } from 'devextreme-react/tooltip';
import FileUploader from 'devextreme-react/file-uploader';
import { uploadFileChunk } from '../../api/s3'
import {Form,
    Label,
    SimpleItem,
    EmptyItem,
    GroupItem,
    ButtonItem,
    RequiredRule,
    EmailRule} from 'devextreme-react/form';
import Button from 'devextreme-react/button';
import { Template } from 'devextreme-react/core/template';
import TextBox from 'devextreme-react/text-box';
import { Popup, Position, ToolbarItem } from 'devextreme-react/popup';
import { useAuth } from '../../contexts/auth'
import { getPrices, updatePrice, deletePrice, getUserDetails, getResources } from '../../api/api';
import { convertRangeArrToFriendlyStr } from '../../utils/miscUtil'
import devices from 'devextreme/core/devices';

const isPhone = devices.current().deviceType === 'phone'


const Services = () => {
    const [loading, setLoading] = useState(true)
    const [servicesList, setServicesList] = useState([])
    const [currentService, setCurrentService] = useState(null)
    const [providerList, setProviderList] = useState([]);
    const [facilityList, setFacilityList] = useState([]);
    const [schedulingSettings, setSchedulingSettings] = useState([])
    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();
    const dataGridRef = useRef()
    const formRef = useRef()
    const { user, refreshSession } = useAuth();
    const fileExtensions = ['.jpg', '.jpeg', '.png'];


    useEffect(() => {
        if (user.signInUserSession) {
            getPrices(user, (e) => {
              setServicesList(e.prices);
              setLoading(false);
              if ((searchParams.get('setupPopup') === 'add') && (e.prices.length === 0)) {
                onAdd();
              }
            }, err => console.log('onErr - getPrices', err))
            getResources(user, onGetResourcesSuccess, err => console.log(err));
            getUserDetails(user, d => setSchedulingSettings(d.scheduling_settings), err => console.log('onErr - getUserDetails', err))
        }
    }, [user && user.signInUserSession, user.signInUserSession.idToken.payload]);
    console.log('providerList: ', providerList)
    console.log('facilityList: ', facilityList)

    const onGetResourcesSuccess = useCallback((data) => {
        let lst = []
        let lst2 = []
        for (let i = 0; i < data.length; i++) {
          if (data[i].resource_type === 'provider') {
            lst.push(data[i])
          }
          if (data[i].resource_type === 'facility') {
            lst2.push(data[i])
          }
        }
        setProviderList(lst);
        setFacilityList(lst2)
      }, [])

    const onAdd = () => {
      setCurrentService(
        {
          resource_id: crypto.randomUUID(),
          details: {}
        });
    }

    const insertRow = e => {
        if(!formRef.current?.instance.validate().isValid) return // form validation

        if(currentService.service_id){
            return updateRow()
        }
        setLoading(true)

        let updaterObj = {}
        updaterObj['service_id'] = crypto.randomUUID()
        updaterObj['details'] = currentService.details

        // return console.log('updaterObj', updaterObj)
        updatePrice(
            user,
            updaterObj,
            r => {
                console.log('on insert success: ', r)
                setLoading(false)
                getPrices(user, (e) => {setServicesList(e.prices); console.log('***********')}, err => console.log('onErr', err))
                setCurrentService(false)
                if (searchParams.get('setupMode')) {
                  navigate('/home?setupMode=true');
                }
            },
            err => console.warn('err: ', err)
        )
    }

    const updateRow = e => {
        setLoading(true)
        updatePrice(user, currentService,
            r => {
                getPrices(user, (e) => {setServicesList(e.prices); console.log('***********')}, err => console.log('onErr', err))
                console.log('on update success: ', r)
                setCurrentService(null)
                setLoading(false)
            },
            err => console.warn('err: ', err))
    }

    const removeRow = e => {
        setLoading(true)
        deletePrice(
            user, e.data,
            data => {
                console.log('on delete success: ', data)
                setLoading(false)
            },
            err => console.warn(err)
        )
    }

    useEffect( () => {
        return console.log('currentService: ', currentService)
    }, [currentService])

    const onUploadChunk = (file, uploadInfo) => {
        return uploadFileChunk(file, uploadInfo, {path:'images/'+currentService.service_id});
    }

    const onUploaded = (e) => {
        let data = {...currentService};
        if (data === null) {
            data = {}
        }
        data.details.picture = "https://s3.us-east-2.amazonaws.com/com.wdttx.scheduler/images/"+currentService.service_id+"/"+encodeURIComponent(e.file.name).replace(/%20/g,'+');
        setCurrentService(data);
    }

    const cellRender = (data) => {
        return (
          <div
            style={{
              backgroundImage: `url("${data.value?data.value:'/DefaultAvatar.png'}")`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              width: "50px",
              height: "50px",
              borderRadius: "25px",
            }}
          />
        )
    }


    useEffect( () => {
        console.log('currentService: ', currentService)
    }, [currentService])


    if(!loading) {
        return (
            <div className="content-block mt-5">
                <div className="d-flex flex-row justify-content-between align-items-center content-block">
                    <h2 className={''}>Services</h2>
                    <Button
                        type="default"
                        text="Add Service"
                        onClick={onAdd}
                    />
                </div>
                <DataGrid
                    ref={dataGridRef}
                    dataSource={servicesList}
                    readOnly={true}
                    showSortIndexes={true}
                    allowColumnResizing={true}
                    columnHidingEnabled={true}
                    onRowRemoved={removeRow}
                >
                    <Column
                        dataField={'details.picture'}
                        width={80}
                        caption={'Picture'}
                        // hidingPriority={1}
                        cellRender={cellRender}
                    />
                    <Column
                       dataField="details.service_title"
                       caption="Title"
                       dataType="text"
                       visibleIndex={1}
                    //    hidingPriority={5}
                    //    calculateDisplayValue={renderRideTypeDisplayVal}
                       allowEditing={false}
                    //    cellRender={renderRideTypeCell}
                    />
                    <Column
                        dataField="details.description"
                        caption="Description"
                        dataType="text"
                        // calculateDisplayValue={val =>  convertRangeArrToFriendlyStr(val.details.passenger_range)}
                        allowEditing={false}
                        visibleIndex={4}
                    />
                    <Column
                        dataField="details.duration"
                        caption="Duration (minutes)"
                        dataType="text"
                        visibleIndex={2}
                        // calculateDisplayValue={val =>  convertRangeArrToFriendlyStr(val.details.luggage_range)}
                        allowEditing={false}
                    />
                    <Column
                        dataField="details.price"
                        caption="Price"
                        dataType="text"
                        visibleIndex={3}
                        // calculateDisplayValue={val =>  convertRangeArrToFriendlyStr(val.details.distance_range)}
                        allowEditing={false}
                    />
                    <Column
                        dataField="details.hidden"
                        caption="Hidden"
                        dataType="text"
                        visibleIndex={4}
                        calculateDisplayValue={s => {
                            if (s.details.hidden) {
                                return 'Yes'
                            } else {
                                return 'No'
                            }
                        }}
                        allowEditing={false}
                    />
                    <Column
                        dataField="details.default_service_provider"
                        caption="Default Team Member"
                        dataType="text"
                        visibleIndex={5}
                        calculateDisplayValue={val => {
                            const defaultProvider = providerList.find(f => f.resource_id === val.details.default_service_provider)
                            if(defaultProvider){
                                return defaultProvider.resource_details.name
                            }
                        }}
                        allowEditing={false}
                    />
                    <Column
                        dataField="details.default_facility"
                        caption="Default Facility"
                        dataType="text"
                        visibleIndex={5}
                        calculateDisplayValue={val => {
                            const defaultFacility = facilityList.find(f => f.resource_id === val.details.default_facility)
                            if(defaultFacility){
                                return defaultFacility.resource_details.name
                            }
                        }}
                        allowEditing={false}
                    />

                    <Column
                        type="buttons"
                        width={isPhone && '65px'}
                        visibleIndex={6}
                    >
                        <DGButton
                            text="Edit"
                            icon="fas fa-pen fs-6"
                            hint="Edit"
                            onClick={(e) => {
                                const data = structuredClone(e.row.data)
                                setCurrentService(data);
                            }}
                        />
                        <DGButton name="delete" />
                    </Column>
                    <Editing
                        allowDeleting={true}
                    >
                    </Editing>
                </DataGrid>

                <Popup
                    visible={currentService && currentService !== null}
                    title={currentService?.owner_id ? 'Edit Service' : 'Add Service'}
                    fullScreen={isPhone}
                    onHidden={() => setCurrentService(null)}
                    dragEnabled={false}
                    width={480}
                >
                    <ToolbarItem
                        widget="dxButton"
                        toolbar="bottom"
                        location="before"
                        options={{
                            disabled: false,
                            text: 'Save',
                            type: 'default',
                            width: '100%',
                            onClick: insertRow
                        }}
                    />
                    <ToolbarItem
                        widget="dxButton"
                        toolbar="bottom"
                        location="after"
                        options={{
                            disabled: false,
                            text: 'Cancel',
                            width: '100%',
                            onClick: () => {
                                console.log('currentService on cancel: ', currentService)
                                setCurrentService(null)
                            }
                        }}
                    />
                    { currentService && <>

                        <div className={'d-flex flex-column align-items-center'}>
                            <div className={'text-center form-avatar'}>
                                {(currentService.details && currentService.details.picture) ? <div
                                    style={{
                                        backgroundImage: `url(${currentService.details.picture})`,
                                        backgroundSize: 'cover',
                                        backgroundRepeat: "no-repeat",
                                        backgroundPosition: "center",
                                        width: '100%',
                                        aspectRatio: 1
                                    }}
                                />:
                                <img
                                    alt={''}
                                    src={'/DefaultAvatar.png'}
                                />}
                            </div>
                            <div className="d-flex text-center" style={{width: "170px"}}>
                                <FileUploader
                                    selectButtonText="Update Image"
                                    labelText=""
                                    multiple={false}
                                    chunkSize={6000000}
                                    uploadChunk={onUploadChunk}
                                    onUploaded={onUploaded}
                                    allowedFileExtensions={fileExtensions}
                                />
                            </div>

                        </div>
                        <Form
                            ref={formRef}
                            id={'form'}
                            formData={currentService}
                            labelLocation={'top'}
                            // onFieldDataChanged={onFormDataChanged}
                        >
                            {/* <Position at="center" my="center"collision="fit" /> */}

                            <SimpleItem
                                dataField="details.service_title"
                                editorType="dxTextBox"
                                editorOptions={{
                                    // items: appointmentTypes.filter(item => item.id !== 'unavailable'),
                                    displayExpr: 'text',
                                    valueExpr: 'id',
                                }}
                            >
                                <Label text="Title"/>
                                <RequiredRule message="Service title is required" />
                            </ SimpleItem>

                            <SimpleItem
                                dataField="details.description"
                                editorType="dxTextBox"
                            >
                                <Label text="Description"/>
                            </SimpleItem>

                            <SimpleItem
                                dataField="details.duration"
                                editorType="dxNumberBox"
                            >
                                <Label text="Duration (minutes)"/>
                                <RequiredRule />
                            </ SimpleItem>

                            <SimpleItem
                                dataField="details.price"
                                editorType="dxNumberBox"
                                editorOptions={{
                                    min: 0,
                                    step: 0
                                }}
                            >
                                <Label text="Price"/>
                                <RequiredRule />
                            </ SimpleItem>

                            <SimpleItem
                                dataField="details.hidden"
                                editorType="dxCheckBox"
                            >
                                <Label text="Hide Service" />
                            </SimpleItem>

                            <SimpleItem
                                dataField="details.default_service_provider"
                                editorType="dxSelectBox"
                                editorOptions={{
                                    items: providerList,
                                    displayExpr: "resource_details.name",
                                    valueExpr: "resource_id"
                                }}
                                visible={providerList.length>0}
                            >
                                <Label text="Default Team Member"/>
                            </ SimpleItem>

                            <SimpleItem
                                dataField="details.default_facility"
                                editorType="dxSelectBox"
                                editorOptions={{
                                    items: facilityList,
                                    displayExpr: "resource_details.name",
                                    valueExpr: "resource_id"
                                }}
                                visible={facilityList.length>0}
                            >
                                <Label text="Default Facility"/>
                            </ SimpleItem>

                        </Form>
                    </>}
                </Popup>
            </div>
        )
    } else {
        return (
            <div className="d-flex flex-row justify-content-center align-items-center mt-5 pt-5">
                <LoadIndicator
                    id="large-indicator"
                    height={60}
                    width={60}
                />
            </div>
        )
    }
}


export default Services
