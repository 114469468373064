import React from "react";
import { Button } from 'devextreme-react/button';
import './Hero.scss'
import {useNavigate, useParams} from 'react-router-dom'

export default function Hero(props) {
    const navigate = useNavigate();
    return (
      <div id={props.main_id} className="hero-area overlay" style={props.bg_video_src?{}:{backgroundImage: `url(${props.bg_image_src})` }}>
        {props.bg_video_src && <video autoPlay="autoplay" muted loop className="hero-video">
         <source src={props.bg_video_src} type="video/mp4"/>
        </video> }
        <div className="block">
          <h1>{props.title}</h1>
          <p className="mb-50">{props.subtitle}</p>
          <h2 className="text-primary">{props.slogan}</h2>
          <Button
            id={props.cta_id}
            onClick={() => {navigate("/about/peek-inside")}}
            height="50px"
            type="default"
            className="mt-5"
            text={props.cta_label}
          />
        </div>
      </div>
    );
}
