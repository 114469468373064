import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useSearchParams } from 'react-router-dom'
import { useNavigate } from 'react-router';
import notify from 'devextreme/ui/notify';
import 'devextreme/data/odata/store';
import FileUploader from 'devextreme-react/file-uploader';
import { LoadIndicator } from 'devextreme-react/load-indicator';
import './facilities.scss'
import devices from 'devextreme/core/devices';
import { FacilityCard } from '../../components';
import { NumberBox } from 'devextreme-react/number-box';

import Button from 'devextreme-react/button';
import {Form,
    Label,
    SimpleItem,
    RequiredRule} from 'devextreme-react/form';
import { useAuth } from '../../contexts/auth';
import {getResources, updateResource, deleteResource, getUserDetails} from '../../api/api'
import {uploadFileChunk} from '../../api/s3'
import { Popup, Position, ToolbarItem } from 'devextreme-react/popup';
import {useMap, useMapsLibrary} from '@vis.gl/react-google-maps';

export default function Facilities() {
  const { user, refreshSession } = useAuth();
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const [facilityList, setFacilityList] = useState([]);
  const [currentFacility, setCurrentFacility] = useState(null);
  const [loading, setLoading] = useState(true)

  const [callingAPI, setCallingAPI] = useState(false);
  const fileExtensions = ['.jpg', '.jpeg', '.png'];
  const formRef = useRef(null);

  const map = useMap();
  const places = useMapsLibrary('places');
  const routes = useMapsLibrary('routes');

  const [predictionResults, setPredictionResults] = useState([])
  const [autocompleteService, setAutocompleteService] = useState(null);
  const [sessionToken, setSessionToken] = useState();

  useEffect(() => {
    if (!places || !routes) return;
    setAutocompleteService(new places.AutocompleteService());
    setSessionToken(new places.AutocompleteSessionToken());
  }, [map, places, routes]);

  const addressEditorOptions = {
    showDropDownButton: true,
    valueExpr: 'description',
    dataSource: predictionResults,
    onSelectionChanged(args) {
      console.log('onChange', args)
      // if (args.selectedItem) {
      //   e.form.updateData('ride_details.start_address', args.selectedItem.description);
      //   fetchRoutes(e.form);
      // }
    },
    onInput(e) {
      console.log('formRef: ', formRef);
      console.log(e);
      fetchPredictions(e.event.currentTarget.value, formRef);
    },
  }

  const phoneEditorOptions = {
    mask: '(000) 000-0000',
    /*onValueChanged(args) {
      e.form.updateData('customer_data.phone', args.value);
    },*/
  }

  const fetchPredictions = useCallback(
    async (inputValue, form) => {

      if (!autocompleteService || !inputValue) {
        while (predictionResults.length > 0) {
          predictionResults.pop();
        }
        return;
      }

      const request = {input: inputValue, sessionToken};
      const response = await autocompleteService.getPlacePredictions(request);

      while (predictionResults.length > 0) {
        predictionResults.pop();
      }
      for (let i = 0; i < response.predictions.length; i++) {
        predictionResults.push(response.predictions[i]);
      }
    }, [autocompleteService, sessionToken]
  )

  const onSave = async () => {
    setSaveButtonOptions({
      disabled: true,
      text: 'Save',
      type: 'default',
      width: '100%',
      onClick: onSave,
    });
    setCallingAPI(true);
    updateResource(user, currentFacility, onSaveSuccess, onError);
  }

  const onCancel = () => {
    setCurrentFacility(null);
  }

  const [cancelButtonOptions, setCancelButtonOptions] = useState({
    disabled: false,
    text: 'Cancel',
    width: '100%',
    onClick: onCancel,
  });

  const [saveButtonOptions, setSaveButtonOptions] = useState({
    disabled: false,
    text: 'Save',
    type: 'default',
    width: '100%',
    onClick: onSave,
  });

  const onError = useCallback((error) => {
    console.log(error);
    setCallingAPI(false);
    if (error.status === 401) {
      refreshSession();
    } else if (error.status === 402) {
      notify("We cannot add the facility. Please upgrade your plan to continue", 'error', 5000);
      navigate('/billing');
    } else {
      console.log(error);
    }
  }, [refreshSession]);

  const onSaveSuccess = useCallback((data) => {
    getResources(user, onGetResourcesSuccess, onError);
    setCurrentFacility(null);
    if (searchParams.get('setupMode')) {
      navigate('/home?setupMode=true');
    }
  }, [])

  const onGetResourcesSuccess = useCallback((data) => {
    let lst = [];
    for (let i = 0; i < data.length; i++) {
      if (data[i].resource_type === 'facility') {
        // if (!(data[i].resource_details.prices)) {
        //   data[i].resource_details.prices = [];
        // }
        lst.push(data[i])
      }
    }
    setCallingAPI(false);
    setFacilityList(lst);
    setLoading(false);
    if ((searchParams.get('setupPopup') === 'add') && (lst.length === 0)) {
      onAddFacility();
    }
  }, [])

  const onUploadChunk = (file, uploadInfo) => {
    return uploadFileChunk(file, uploadInfo, {path:'images/'+currentFacility.resource_id});
  }

  const onUploaded = (e) => {
    let data = {...currentFacility};
    if (data === null) {
      data = {}
    }
    data.resource_details.picture = "https://s3.us-east-2.amazonaws.com/com.wdttx.scheduler/images/"+currentFacility.resource_id+"/"+encodeURIComponent(e.file.name).replace(/%20/g,'+');
    setCurrentFacility(data);
  }

  const onGetUserDetailsSuccess = useCallback((data) => {
    if (data.credit === 0) {
      navigate('/billing');
    }
  }, [])

  useEffect(() => {
    if (user.signInUserSession) {
      getResources(user, onGetResourcesSuccess, onError);
      getUserDetails(user, onGetUserDetailsSuccess, onError);
    }
  }, [user && user.signInUserSession, user.signInUserSession.idToken.payload]);

  useEffect(() => {
    if (currentFacility) {
      setCancelButtonOptions({
        disabled: false,
        text: 'Cancel',
        width: '100%',
        onClick: onCancel,
      });
      setSaveButtonOptions({
        disabled: !(currentFacility.resource_details.name),
        text: 'Save',
        type: 'default',
        width: '100%',
        onClick: onSave,
      });
    }
  }, [currentFacility]);

  const capacityOptions = {
    min: 1,
    max: 50,
    step: 0,
  };

  const onEditFacility = (facility) => {
    setCurrentFacility(facility);
  }

  const onDeleteFacility = (facility) => {
    setCallingAPI(true);
    deleteResource(user, facility, onSaveSuccess, onError);
  }

  const onSuspendResumeFacility = (facility) => {
    if (facility.resource_details.active) {
      facility.resource_details.active = false;
    } else {
      facility.resource_details.active = true;
    }
    setCallingAPI(true);
    updateResource(user, facility, onSaveSuccess, onError);
  }


  const onFormDataChanged = useCallback(
    (e) => {
      if (currentFacility) {
        setSaveButtonOptions({
          disabled: !(currentFacility.resource_details.name),
        });
      }

    },
    [currentFacility],
  );

  const onAddFacility = () => {
    setCurrentFacility(
      {
        resource_id: crypto.randomUUID(),
        resource_details: {
          capacity: 1,
          // prices: [],
        },
        resource_type: 'facility',
      });
  }

  if(loading) {
    return (
      <div className="d-flex flex-row justify-content-center align-items-center mt-5 pt-5">
        <LoadIndicator
            id="large-indicator"
            height={60}
            width={60}
        />
      </div>
    )
  }

  return (
    <React.Fragment>
      <div className="d-flex flex-row justify-content-between align-items-center content-block">
        <h2 className={''}>Facilities</h2>
        <Button
          type="default"
          text="Add Facility"
          onClick={onAddFacility}
        />
      </div>
      <div className="row">
      { facilityList.map( (facility) =>
        <div className="col-12 col-lg-6 col-xl-4 mb-3" key={facility.resource_id} >
          <FacilityCard
            facility={facility}
            onEdit={onEditFacility}
            onDelete={onDeleteFacility}
            onSuspendResume={onSuspendResumeFacility}
            loading={callingAPI}
          />
        </div>) }
      </div>

      <Popup
        visible={currentFacility !== null}
        onHidden={onCancel}
        dragEnabled={false}
        showCloseButton={false}
        showTitle={true}
        fullScreen={devices.current().deviceType === 'phone'}
        title={currentFacility&&currentFacility.owner_id?"Edit Facility":"Add Facility"}
      >
        <Position at="center" my="center"collision="fit" />
        <ToolbarItem
          widget="dxButton"
          toolbar="bottom"
          location="before"
          options={saveButtonOptions}
        />
        <ToolbarItem
          widget="dxButton"
          toolbar="bottom"
          location="after"
          options={cancelButtonOptions}
        />

        {currentFacility && <div className="">

          <div className={'d-flex flex-column align-items-center'}>
            <div className={'text-center car-image'}>
              {(currentFacility.resource_details && currentFacility.resource_details.picture) ? <img
                alt={''}
                src={currentFacility.resource_details.picture}
              />:
              <img
                alt={''}
                src={'/DefaultFacility.png'}
              />}
            </div>
            <div className="d-flex text-center" style={{width: "170px"}}>
              <FileUploader
                selectButtonText="Update Image"
                labelText=""
                multiple={false}
                chunkSize={6000000}
                uploadChunk={onUploadChunk}
                onUploaded={onUploaded}
                allowedFileExtensions={fileExtensions}
              />
            </div>

          </div>
          <Form
            ref={formRef}
            id={'form'}
            formData={currentFacility}
            labelLocation={'top'}
            onFieldDataChanged={onFormDataChanged}
          >
            <SimpleItem dataField="resource_details.name">
              <Label text="Facility Name (Publicly Visible)"/>
              <RequiredRule message="Facility name is required" />
            </SimpleItem>
            <SimpleItem
              dataField="resource_details.capacity"
              editorType="dxNumberBox"
              helpText="This configures the number of simultaneous appointments that a certrain facility can accept."
              editorOptions={capacityOptions}>
              <Label text="Capacity" />
            </SimpleItem>
            <SimpleItem
              dataField="resource_details.address"
              editorType="dxAutocomplete"
              editorOptions={addressEditorOptions}
            >
              <Label text="Address" />
            </SimpleItem>
            <SimpleItem
              dataField="resource_details.description"
              editorType="dxTextBox"
            >
              <Label text="Description" />
            </SimpleItem>
            <SimpleItem
              dataField="resource_details.phone"
              editorType="dxTextBox"
              editorOptions={phoneEditorOptions}
            >
              <Label text="Phone number" />
            </SimpleItem>
            <SimpleItem
              dataField="resource_details.default"
              editorType="dxCheckBox"
              editorOptions={phoneEditorOptions}
            >
              <Label text="Mark as Default Facility" />
            </SimpleItem>

          </Form>

        </div>}
      </Popup>

    </React.Fragment>
  )

}
