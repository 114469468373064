const appInfo = {
    title: 'ezPlan',
    version: '1.0.0',
    company: 'Woodlands Digital Technologies LLC',
    company_address: '7 Columnberry Ct, The Woodlands, TX 77384',
    app_website_name: 'ezPlan',
    app_website: 'http://www.ezplan.org',
    company_email: 'george@wdttx.com'
};
export default appInfo;
