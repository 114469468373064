import React, {useState, useEffect} from "react";
import { Button } from 'devextreme-react/button';
import './ScheduleDataCell.scss'
import {useNavigate} from 'react-router-dom'
import { renderServiceTitles } from "../../utils/miscUtil";

export default function ScheduleDataCell(props) {
    const userData = null;
    const navigate = useNavigate();

    const getCellCSSClass = (appointment_type, appointment_status) => {
      let css_val = appointment_type + " "

      if (appointment_status === 'pending')
        css_val = css_val + 'appointment_pending';

      if (appointment_status === 'rejected')
        css_val = appointment_type +'_rejected';

      if (appointment_status === 'canceled')
        css_val = appointment_type +'_rejected';

      return css_val
    }

    const apptTitle = renderServiceTitles(props.data.appointmentData.services, props.data.appointmentData)

    return (
      <>
        <div className={"d-flex flex-column justify-content-between h-100 ps-1 pt-1 "+getCellCSSClass(props.data.appointmentData.appointment_type, props.data.appointmentData.status)}
        >
          <div style={{textWrap: 'wrap'}}>{apptTitle}</div>
          <div className="d-flex flex-row">
            {props.data.appointmentData.provider?.resource_details.picture && <div
              style={{
                backgroundColor: "#fff",
                backgroundImage: `url("${ props.data.appointmentData.provider?.resource_details.picture ? props.data.appointmentData.provider.resource_details.picture : '/DefaultAvatar.png'}")`,
                backgroundSize: 'cover',
                width: "25px",
                height: "25px",
                borderRadius: "30px",
              }}
            />}
            <div
              className="ms-1"
              style={{
                backgroundColor: "#fff",
                backgroundImage: `url("${ props.data.appointmentData.facility?.resource_details.picture ? props.data.appointmentData.facility.resource_details.picture : '/DefaultFacility.png'}")`,
                backgroundSize: 'contain',
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                width: "25px",
                height: "25px",
                borderRadius: "2px",
              }}
            />
          </div>
        </div>

      {false && props.data.data.resource_type === "vehicle" &&
        <div className="d-flex flex-row align-items-center justify-content-start">
          <div className={'vehicle-card-image-xs'}>
            {(props.data.data.resource_details && props.data.data.resource_details.picture) ? <img
              alt={''}
              src={props.data.data.resource_details.picture}
            />:
            <img
              alt={''}
              src={'/DefaultFacility.png'}
            />}
          </div>
          <p className="ps-1 m-0">{props.data.data.resource_details.name}</p>
        </div>
      }
      </>
    );
}
