import React, { useState, useRef, useCallback } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Form, {
  Item,
  Label,
  ButtonItem,
  ButtonOptions,
  RequiredRule,
  CustomRule,
  EmailRule
} from 'devextreme-react/form';
import notify from 'devextreme/ui/notify';
import LoadIndicator from 'devextreme-react/load-indicator';
import { createAccount } from '../../api/auth';
import appInfo from '../../app-info';

import './CreateAccountForm.scss';

export default function CreateAccountForm() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const formData = useRef({ email: '', password: '' });

  const onSubmit = useCallback(async (e) => {
    e.preventDefault();
    const { email, password } = formData.current;
    setLoading(true);
    try {
      const result = await createAccount(email, password);
      setLoading(false);

      if (result.isOk) {
        navigate('/confirm-account');
      } else {
        if (result.message.includes("policy")) {
          notify(result.message+". The password has to be at least 8 characters long and contain 1 number, 1 special character (^ $ * . [ ] { } ( ) ? - ! @ # % & / \ , > < ' : ; | _ ~ ` + =), 1 uppercase letter and 1 lowercase letter.", 'error', 10000);
        } else {
          notify(result.message, 'error', 5000);
        }

      }
    } catch(error) {
      if (error.message.includes("policy")) {
        notify(error.message+". The password has to be at least 8 characters long and contain 1 number, 1 special character (^ $ * . [ ] { } ( ) ? - ! @ # % & / \ , > < ' : ; | _ ~ ` + =), 1 uppercase letter and 1 lowercase letter.", 'error', 10000);
      } else {
        notify(error.message, 'error', 5000);
      }
      setLoading(false);
    }

  }, [navigate]);

  const confirmPassword = useCallback(
    ({ value }) => value === formData.current.password,
    []
  );

  return (
    <form className={'create-account-form'} onSubmit={onSubmit}>
      <Form formData={formData.current} disabled={loading}>
        <Item
          dataField={'email'}
          editorType={'dxTextBox'}
          editorOptions={emailEditorOptions}
        >
          <RequiredRule message="Email is required" />
          {false &&<EmailRule message="Email is invalid" />}
          <Label visible={false} />
        </Item>
        <Item
          dataField={'password'}
          editorType={'dxTextBox'}
          editorOptions={passwordEditorOptions}
        >
          <RequiredRule message="Password is required" />
          <Label visible={false} />
        </Item>
        <Item
          dataField={'confirmedPassword'}
          editorType={'dxTextBox'}
          editorOptions={confirmedPasswordEditorOptions}
        >
          <RequiredRule message="Password is required" />
          <CustomRule
            message={'Passwords do not match'}
            validationCallback={confirmPassword}
          />
          <Label visible={false} />
        </Item>
        <Item>
          <div className='policy-info'>
            By creating an account, you agree to the <Link to={appInfo.app_website+"/legal/tos"} target="_">Terms of Service</Link>
            {" and "}<Link to={appInfo.app_website+"/legal/pp"} target="_">Privacy Policy</Link>
          </div>
        </Item>
        <ButtonItem>
          <ButtonOptions
            width={'100%'}
            type={'default'}
            useSubmitBehavior={true}
          >
            <span className="dx-button-text">
              {
                loading
                  ? <LoadIndicator width={'24px'} height={'24px'} visible={true} />
                  : 'Create a new account'
              }
            </span>
          </ButtonOptions>
        </ButtonItem>
        <Item>
          <div className={'login-link'}>
            Have an account? <Link to={'/login'}>Sign In</Link>
          </div>
        </Item>
      </Form>
    </form>
  );
}

const emailEditorOptions = { stylingMode: 'filled', placeholder: 'Email', mode: 'email' };
const passwordEditorOptions = { stylingMode: 'filled', placeholder: 'Password', mode: 'password' };
const confirmedPasswordEditorOptions = { stylingMode: 'filled', placeholder: 'Confirm Password', mode: 'password' };
