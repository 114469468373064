import React, { useState, useEffect, useCallback } from 'react';
import { useSearchParams } from 'react-router-dom'
import 'devextreme/data/odata/store';
import './schedule.scss'
import { Scheduler } from '../../components';
import Button from 'devextreme-react/button';
import { TagBox } from 'devextreme-react/tag-box';
import { useAuth } from '../../contexts/auth';
import { LoadIndicator } from 'devextreme-react/load-indicator';
import devices from 'devextreme/core/devices';
import GroupByBttn from '../../components/scheduler/GroupByBttn';
import notify from 'devextreme/ui/notify';
import {
  getResources, getUserDetails, appointmentTypes, appointmentStatusValues, updateAppointment,
  getAppointments, deleteAppointment, updateResource, inviteDriversToAppointment, getPublicServices
} from '../../api/api'
import { injectDataInAppointment } from "../../utils/miscUtil";

const isPhone = devices.current().deviceType === 'phone'

export default function Schedule() {
  const no_facility = {resource_type: "facility", resource_id: "00000000-0000-0000-0000-000000000000", resource_details: {name: 'No Facility'}};
  const no_provider = {resource_type: "provider", resource_id: "00000000-0000-0000-0000-000000000000", resource_details: {name: 'No Team Member'}};
  const { user, refreshSession } = useAuth();
  const [services, setServices] = useState(null)
  const [resources, setResources] = useState(null)
  const [facilityList, setFacilityList] = useState([]);
  const [facilityFilteredList, setFacilityFilteredList] = useState([]);
  const [providerList, setProviderList] = useState([]);
  const [providerListOriginal, setProviderListOriginal] = useState([]);
  const [providerFilteredList, setProviderFilteredList] = useState([]);
  const [customerList, setCustomerList] = useState([])
  const [appointmentData, setAppointmentData] = useState([]);
  const [appointmentFilteredData, setAppointmentFilteredData] = useState(null);
  const [callingAPI, setCallingAPI] = useState(false);
  const [showAppointment, setShowAppointment] = useState(null);
  const [userSettings, setUserSettings] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const [apptId, setApptId] = useState(null);


  useEffect( () => {
    // refresh frontend data
    if(user && (appointmentData.length>0)){
      setInterval( () => {
        getResources(
            user,
            onRSuccess,
            (err)=>{console.log(err)
          }
        )
      }, 1000*60*60)
    }

    const onRSuccess = (data) => {
      setResources(data);
      getAppointments(user, onASuccess, err => console.log(err))
    }

    const onASuccess = d => {
      console.log(services);
      let arr1 =[]
      let arr2 =[]
      for(const item of d) {
        const obj = {}
        obj['apptId'] = item['appointment_id']
        obj['apptStatus'] = item['status']
        arr1.push(obj)
      }
      for(const item of appointmentData) {
        const obj2 = {}
        obj2['apptId'] = item['appointment_id']
        obj2['apptStatus'] = item['status']
        arr2.push(obj2)
      }

      const isDataEqual = JSON.stringify(arr1) === JSON.stringify(arr2)
      if(!isDataEqual) {
        console.log("***** stale data - update the react state with new data")
        window.location.reload()
      }else{
        console.log("scheduler data on frontend is up to date")
      }
    }

  }, [user, appointmentData])



  const onError = useCallback((error) => {
    setCallingAPI(false);
    if(error.status === 409) {
      notify('Sorry, we could not update the ride. Please try again!', 'error', 8000)
      getResources(user, onGetResourcesSuccess, onError);
    } else if (error.status === 401) {
      refreshSession();
    } else {
      console.log(error);
    }
  }, [refreshSession]);

  const onGetAppointmentsSuccess = (apptsData) => {
    setAppointmentData(apptsData);
    setAppointmentFilteredData(apptsData);
  }

  const onGetResourcesSuccess = (data) => {
    setResources(data);
  }

  const onAppointmentChangedSuccess = (data, options) => {
    if(data.status === 'pending' && data.facility_id && data.provider_id) {
      if(options?.action !== 'deletion') {
        notify(`We noticed that you assigned a facility and a provider but the status of the booking is still "Submitted"; the customer is still waiting for you to accept the appointment`, 'warning', 10000);
      }
    }

    if(options?.action !== 'deletion') {
      for (let i = 0; i < appointmentData.length; i++) {
        if (data.appointment_id === appointmentData[i].appointment_id) {
          appointmentData[i]=data;
          setAppointmentData([...appointmentData])
        }
      }
    } else {
      let new_app_data = [];
      for (let i = 0; i < appointmentData.length; i++) {
        if (data.appointment_id !== appointmentData[i].appointment_id) {
          new_app_data.push(appointmentData[i]);
        }
      }
      setAppointmentData(new_app_data);
      new_app_data = [];
      for (let i = 0; i < appointmentFilteredData.length; i++) {
        if (data.appointment_id !== appointmentData[i].appointment_id) {
          new_app_data.push(appointmentData[i]);
        }
      }
      setAppointmentFilteredData(appointmentFilteredData)
    }
    if (searchParams.get('setupMode')) {
      searchParams.delete('setupMode')
      setSearchParams(searchParams);
    }
  }

  const onAppointmentSave = (data) => {
    updateAppointment(user, data, onAppointmentChangedSuccess, onError)
  }

  const onAppointmentDelete = (data) => {
    deleteAppointment(user, data, data => onAppointmentChangedSuccess(data, {action: 'deletion'}), onError);
  }

  const onInviteProviders = (data) => {
    console.log(data);
    inviteDriversToAppointment(user, data.appointment_id,
      data => {
        notify("Invitation sent", 'success', 3000);
      },
      err => {
        notify("Something went wrong. Please refresh and try again.", 'error', 3000);
        console.log(err);
      })
  }

  useEffect(() => {
    if (apptId) {
      setShowAppointment({id: apptId})
    }
  }, [apptId]);

  useEffect(() => {
    if (user.signInUserSession) {
      setCallingAPI(true);
      getResources(user, onGetResourcesSuccess, onError);
      getPublicServices(user.signInUserSession.idToken.payload.sub,
        data => {
          const serviceUnavailable = {
            owner_id: user.signInUserSession.idToken.payload.sub,
            service_id: "00000000-0000-0000-0000-000000000000",
            details: {
              service_title: 'Unavailable',
              picture: '/Unavailable.png'
            }
          }
          data.services.push(serviceUnavailable)
          setServices(data.services);
        }, onError)

      getUserDetails(user, data => {
        if('scheduling_settings' in data) {
          setUserSettings(data.scheduling_settings);
        }
      }, onError)

      getAppointments(user, onGetAppointmentsSuccess, onError);
    }
  }, [user && user.signInUserSession, user.signInUserSession.idToken.payload]);

  useEffect(() => {
    if (services && resources && appointmentData) {
      setCallingAPI(false);
      for (let j = 0; j<appointmentData.length; j++ ) {
        injectDataInAppointment(appointmentData[j], resources, services)
      }
      setApptId(searchParams.get('apptId'));
      setSearchParams({});
    }
  }, [services, resources, appointmentData]);

  useEffect(() => {
    if (resources) {
      let lst1 = [];
      let lst2 = [no_provider];
      let lst3 = []

      for (let i = 0; i < resources.length; i++) {
        if (resources[i].resource_type === 'facility') {
          lst1.push(resources[i]);
        }
        if (resources[i].resource_type === 'provider') {
          lst2.push(resources[i]);
        }

        if (resources[i].resource_type === 'customer') {
          lst3.push(resources[i]);
        }
      }
      setFacilityList(structuredClone(lst1));
      setFacilityFilteredList(structuredClone(lst1));
      setProviderList(structuredClone(lst2));
      setProviderFilteredList(structuredClone(lst2));
      setProviderListOriginal(structuredClone(lst2));
      setCustomerList(structuredClone(lst3));
    }

  }, [resources]);

  const onFacilityFilter = (data) => {
    if (data.value.length === 0) {
      setFacilityFilteredList(facilityList);
    } else {
      setFacilityFilteredList(data.value);
    }
  }

  const onProviderFilter = (data) => {
    if (data.value.length === 0) {
      setProviderFilteredList(providerList);
    } else {
      setProviderFilteredList(data.value);
    }
  }

  useEffect( () => {
    //console.log('appointmentFilteredData: ', appointmentFilteredData)
  }, [appointmentFilteredData])

  return (
    <React.Fragment>
      {userSettings && (callingAPI === false) ?
      <>
      <div className="d-flex flex-row justify-content-between align-items-center ">
        <h2 className={''}>My Schedule</h2>

        <div className="d-flex">
          <Button
            type="default"
            text={ isPhone ? "+" : "Add Entry"}
            onClick={() => {
              setShowAppointment({});
            }}
          />
        </div>
      </div>
      
      { isPhone && (
          <div className="mb-5">
            <GroupByBttn className="w-100 mb-2" userSettings={userSettings} onUserSettingsUpdate={ data => setUserSettings(data)}/>
          </div>
        )
      }
      <div className="d-flex flex-row w-100 gap-2">
        
      { !isPhone && <GroupByBttn className="w-100" userSettings={userSettings} onUserSettingsUpdate={ data => setUserSettings(data)}/> }
        <div className="mb-3 w-100">
          <TagBox
            label="Filter Facilities"
            height="45px"
            dataSource={facilityList}
            itemRender={FacilitySelectItem}
            tagRender={FacilitySelectTag}
            onValueChanged={onFacilityFilter}
          />
        </div>
        <div className="mb-3 w-100">
          <TagBox
            label="Filter Team Members"
            height="45px"
            dataSource={providerList}
            itemRender={ProviderSelectItem}
            tagRender={ProviderSelectTag}
            onValueChanged={onProviderFilter}
          />
        </div>
      </div>

          <Scheduler
            id="scheduler"
            appointmentData={appointmentFilteredData}
            facilityList={facilityFilteredList}
            providerList={providerFilteredList}
            customerList={customerList}
            services={services}
            resources={resources}
            appointmentStatusValues={appointmentStatusValues.filter(val => { return val.owner_allowed; })}
            settings={userSettings}
            onAppointmentSave={onAppointmentSave}
            onAppointmentDelete={onAppointmentDelete}
            showAppointment={showAppointment}
            onInvite={onInviteProviders}
          >
          </Scheduler>

      </>:
      <div className="d-flex flex-row justify-content-center align-items-center mt-5 pt-5">
        <LoadIndicator
            id="large-indicator"
            height={60}
            width={60}
          />
          </div>
        }

    </React.Fragment>
)}

const FacilitySelectItem = (data2) => {
  const data = { data: data2 };
  let defaultFacilityImage = "DefaultFacility.png"
  if (data.data.resource_id == "00000000-0000-0000-0000-000000000001") {
    defaultFacilityImage = "DefaultFacility2.png"
  }
  return (
    <div className="d-flex flex-row justify-content-start align-items-center">
      <div
        style={{
          backgroundColor: "#fff",
          backgroundImage: `url("${ data.data?.resource_details.picture ? data.data.resource_details.picture : defaultFacilityImage }")`,
          backgroundSize: 'cover',
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          width: "35px",
          aspectRatio: "1",
          borderRadius: "50%",
        }}
      />
      { data.data && <div className="ms-2" style={{width: '70%'}}>{data.data.resource_details.name}</div> }
    </div>
  );
}

const FacilitySelectTag = (data) => {
  let defaultFacilityImage = "DefaultFacility.png"
  if (data.resource_id == "00000000-0000-0000-0000-000000000001") {
    defaultFacilityImage = "DefaultFacility2.png"
  }
  return (
    <React.Fragment>
      <div className={`dx-tag-content`} >
        <div
          className="me-1"
            style={{
              backgroundColor: "#fff",
              backgroundImage: `url("${data.resource_details.picture ? data.resource_details.picture : defaultFacilityImage }")`,
              backgroundSize: 'cover',
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              width: "15px",
              height: "15px",
              borderRadius: '50%'
            }}
        />
        <span className="text-xs">{data.resource_details.name}</span>
        <div className="dx-tag-remove-button"></div>
      </div>
    </React.Fragment>

  );
}

const ProviderSelectItem = (data2) => {
  const data = { data: data2 };
  return (
    <div className="d-flex flex-row justify-content-start align-items-center">
      <div
        style={{
          backgroundColor: "#fff",
          backgroundImage: `url("${data.data && data.data.resource_details.picture?data.data.resource_details.picture:'/DefaultAvatar.png'}")`,
          backgroundSize: 'cover',
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          width: "35px",
          aspectRatio: "1",
          borderRadius: "50%",
        }}
      />
      {data.data && <div className="ms-2" style={{width: '70%'}}>{data.data.resource_details.name}
      </div>}
    </div>
  );
}

const ProviderSelectTag = (data) => {
  return (
    <React.Fragment>
      <div className={`dx-tag-content`} >
        <div
          className="me-1"
            style={{
              backgroundColor: "#fff",
              backgroundImage: `url("${data.resource_details.picture?data.resource_details.picture:'/DefaultAvatar.png'}")`,
              backgroundSize: 'cover',
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              width: "15px",
              height: "15px",
              borderRadius: '50%'
            }}
        />
        <span>{data.resource_details.name}</span>
        <div className="dx-tag-remove-button"></div>
      </div>
    </React.Fragment>

  );
}
