import React, { useState, useEffect, useCallback, useRef } from "react";
import { useParams, useNavigate, useSearchParams } from 'react-router-dom'
import Form, { Item, EmailRule, RequiredRule, Label, RangeRule} from 'devextreme-react/form';
import { Button } from 'devextreme-react/button';
import { Template } from 'devextreme-react/core/template';
import TextBox from 'devextreme-react/text-box';
import { ProgressBar } from 'devextreme-react/progress-bar';
import { getPublicAppointmentById, updatePublicAppointmentById, appointmentStatusValues, getPricesForAppointment, getPublicServices, getPublicServiceProviders, getPublicFacilities, getAvailableTimes } from "../../api/api";
import { removeSeconds, calculatePrices } from "../../utils/miscUtil";
import { customizeNewDate, parseHours, renderServiceTitles, getAppointmentPriceAndDuration, getDisabledDates, customizeEndCalendarDate } from "../../utils/miscUtil";
import { confirm } from "devextreme/ui/dialog"
import { LoadIndicator } from 'devextreme-react/load-indicator';
import './BookService.scss'
import LabelTemplate from '../templates/LabelTemplate'
import { useMap, useMapsLibrary } from '@vis.gl/react-google-maps';


const BookService = props => {
  const [step, setStep] = useState(0)
  const [formData, setFormData] = useState({})
  const [prices, setPrices] = useState(null);
  const [services, setServices] = useState(null)
  const [providers, setProviders] = useState(null)
  const [facilities, setFacilities] = useState(null)
  const [defaultFacility, setDefaultFacility] = useState(null)
  const [isProvider, setIsProvider] = useState(false);
  const [errorMessage, setErrorMessage] = useState("Something went wrong! Try again.");

  const [predictionResults, setPredictionResults] = useState([])
  const [autocompleteService, setAutocompleteService] = useState(null);
  const [sessionToken, setSessionToken] = useState();

  const [loadIndicatorVisible, setLoadIndicatorVisible] = useState()
  const [isTimeFieldDisabled, setIsTimeFieldDisabled] = useState(true)

  const [searchParams] = useSearchParams()
  const [availableServiceTimes, setAvailableServiceTimes] = useState()

  const navigate = useNavigate()
  const params = useParams()
  const formRef = useRef()
  const nextBtnRef = useRef()

  const map = useMap();
  const places = useMapsLibrary('places');
  const routes = useMapsLibrary('routes');

  const steps = ["service", "personal_info", "timing", "quote", "summary", "payment"]
  const minCalendarTime = customizeNewDate(2)

  const ownerId = props.profileData?.owner_id;
  const providerParam = searchParams.get('provider')
  let appointmentId = params.appointmentId

  const pending = appointmentStatusValues.find(element => element.id==='pending')
  const available = appointmentStatusValues.find(element => element.id==='available')
  const rejected = appointmentStatusValues.find(element => element.id==='rejected')
  const canceled = appointmentStatusValues.find(element => element.id==='canceled')
  const paid = appointmentStatusValues.find(element => element.id==='payed')
  const unpaid = appointmentStatusValues.find(element => element.id==='unpayed')
  const completed = appointmentStatusValues.find(element => element.id==='completed')

  const isServiceProviderSettingOn = props.profileData?.scheduling_settings?.service_provider_option
  const isFacilitySettingOn = props.profileData?.scheduling_settings?.facility_option

  const showAvailability2Public = props.profileData?.scheduling_settings?.show_availability_to_public
  const showCustomerAddress = props.profileData?.scheduling_settings?.show_customer_address_option
  const stripeLink = props.profileData?.scheduling_settings?.reservation_payment_link
  const multipleServiceSelection = !!props.profileData?.scheduling_settings?.multiple_service_selection

  useEffect( () => {
    getPublicServices(ownerId, data => {
      const unhiddenServices = data.services.filter(s => !s.details.hidden)
      console.log('services (unhidden): ', unhiddenServices);
      setServices(unhiddenServices)
    }, err => console.log((err)))
    getPublicServiceProviders(ownerId, data => {console.log('providers: ', data.providers); setProviders(data.providers)}, err => console.log((err)))
    getPublicFacilities(ownerId, onGetFacilitiesSuccess, err => console.log((err)))
  }, [])

  useEffect(() => {
    if (!places || !routes) return;
    setAutocompleteService(new places.AutocompleteService());
    setSessionToken(new places.AutocompleteSessionToken());
  }, [map, places, routes]);

  const fetchPredictions = useCallback(
    async (inputValue, form) => {
      if (!autocompleteService || !inputValue) {
        while (predictionResults.length > 0) {
          predictionResults.pop();
        }
        return;
      }

      const request = {input: inputValue, sessionToken};
      const response = await autocompleteService.getPlacePredictions(request);

      while (predictionResults.length > 0) {
        predictionResults.pop();
      }
      for (let i = 0; i < response.predictions.length; i++) {
        predictionResults.push(response.predictions[i]);
      }
    }, [autocompleteService, sessionToken]
  )

  const onGetFacilitiesSuccess = (data) => {
    console.log('facilities: ', data.facilities);
    setFacilities(data.facilities.filter(f => f.resource_id !== "00000000-0000-0000-0000-000000000001"));
    var defFac = null;
    if (data.facilities.length===0) {
      setErrorMessage('This business has no public facilities');
      setStep('error');
      return
    }

    defFac = data.facilities[0]
    for (var i=0; i<data.facilities.length; i++){
      if (data.facilities[i].resource_details.default) { 
        defFac = data.facilities[i]
      }
    }

    console.log("default facility:",defFac);
    setDefaultFacility(defFac);
  }

  useEffect( () => {
    if (services) {
      if (services.length === 0){
        setErrorMessage('This business has no public services');
        setStep('error');
      }
    }
  }, [services])


  const getAvailableServiceHours = useCallback( () => {
    setLoadIndicatorVisible(true)
    setIsTimeFieldDisabled(true)
    setAvailableServiceTimes([])
    const data = {}
    data['appointment'] = formRef.current?.instance.option().formData
    data['scheduling_settings'] = props.profileData?.scheduling_settings
    getAvailableTimes(
      ownerId,
      data,
      r => {
        setLoadIndicatorVisible(false)
        setIsTimeFieldDisabled(false)
        setAvailableServiceTimes(r)
      },
      err => console.warn('err: ', err)
    )
  }, [])

  useEffect( () => {

    if(props.profileData && appointmentId && defaultFacility && services && providers) {
      console.log(props);
      getPublicAppointmentById(
        props.profileData['owner_id'],
        appointmentId,
        data => {
          // data.time = new Date(data.time)
          console.log('data: ', data)
          if(!data) return
          setPrices(calculatePrices(data.pricing))
          setFormData(data)
          if (providerParam) {
            if (data.provider_id === providerParam) {
              setIsProvider(true)
            } else {
              console.log('err: ', 'error step')
              setStep('error')
              return;
            }
          }


          if(data?.status === pending.id) {
            setStep("summary")
          }
          if(data.status === available.id) {
            setStep("payment")
          }
          if(data.status === paid.id || data.status === unpaid.id) {
            setStep("confirmed")
          }
          if(data.status === rejected.id) {
            setStep('rejected')
          }
          if(data.status === canceled.id) {
            setStep('canceled')
          }
          if(data.status === completed.id) {
            setStep(completed.id)
          }
        },
        err => {
          console.log('err: ', 'error step');
          setStep('error');
        }
      )
    }
  }, [props.profileData, appointmentId, defaultFacility, services, providers])

  const renderServicesInfo = useCallback( (options = {renderTitlesOnly: false}) => {
    const data = formRef.current?.instance.option().formData || formData;
    const formSelectedServices = data.appointment_type

    console.log('formSelectedServices: ', formSelectedServices)
    console.log('services: ', services)
    console.log('appt: ', data);
    if(services && services != null && formSelectedServices){
      let titlesStr = renderServiceTitles(services, data);
      let priceAndDuration = getAppointmentPriceAndDuration(services, data);

      if(options.renderTitlesOnly) {
        return titlesStr
      } else {
        const startDate = formRef.current.instance.option().formData.startDate
        const endDate = new Date(startDate.getTime() + 60*priceAndDuration.duration*1000)
        formRef.current?.instance.updateData('endDate', endDate)
        formData.endDate = endDate
        console.log(priceAndDuration);
        if (priceAndDuration.price) {
          return <p className="fs-6 text-center">{titlesStr} will cost <b>${priceAndDuration.price}</b>+tax and it will take approximately <b>{priceAndDuration.duration} minutes.</b></p>
        }
        return <p className="fs-6 text-center">{titlesStr} will take approximately <b>{priceAndDuration.duration} minutes.</b></p>
      }
    }
  }, [services, formData])

  const renderMarkServiceAsCompletedBttn = useCallback( () => {
    if(isProvider) {
      return (
        <Button
          type="default"
          stylingMode="outlined"
          className="mt-2"
          disabled={loadIndicatorVisible}
          onClick={() => {
            let result = confirm("<i>Are you sure?</i>", "Confirm");
            result.then(dialogResult => {
              if(dialogResult) {
                saveBooking(completed.id)
              }
            }
            )
          }
        }
        >
          <LoadIndicator
              height={20}
              width={20}
              className="button-indicator text-success me-2"
              visible={loadIndicatorVisible}
          />
          <span className="dx-button-text">Mark as Completed</span>
        </Button>
      )
    }
  }, [loadIndicatorVisible, formData])


  const saveBooking = useCallback( (status=pending.id) => {
    setLoadIndicatorVisible(true)
    formData.status = status;

    let apptId = null;
    formData.owner_id = ownerId
    if (formData.appointment_id) {
      apptId = formData.appointment_id
    }
    if (appointmentId) {
      apptId = appointmentId
    }
    if (apptId === null) {
      apptId = crypto.randomUUID()
    }
    formData.appointment_id = apptId

    console.log("formData: ", formData)
    updatePublicAppointmentById(
      ownerId,
      formData.appointment_id,
      formData,
      (data)=>{
        if(status === pending.id) {
          setStep("summary")
        }
        if(status === canceled.id) {
          setStep('canceled')
        }
        if(status === paid.id) {
          setStep('confirmed')
        }
        if(status === unpaid.id) {
          setStep('confirmed')
        }
        if(status === completed.id) {
          setStep(completed.id)
        }
        if(!appointmentId) {
          navigate(`/book/${props.profileData?.company_profile}/${data.appointment_id}`)
        }
        setLoadIndicatorVisible(false)
      },
      (err)=>{
        console.log('err: ', 'error step')
        setStep('error');
        console.log('err: ', err)
        setLoadIndicatorVisible(false)
      }
    )
  }, [formData, step])

  const onApptDeletion = () => {
    saveBooking('canceled')
  }

  const handleformValidation = useCallback( () => {
    let result = false
    if(step === "service"){
      result = formRef.current?.instance.validate()
    }
    if(step === "personal_info") {
      result = formRef.current?.instance.validate()
    }
    if(step === "timing") {
      result = formRef.current?.instance.validate()
    }
    if (step === "quote") {
      nextStep();
      return
    }
    if(result.isValid){
      nextStep()
    }
  }, [step, formRef])

  const nextStep = () => {
    if(step === "service"){
      return setStep("personal_info")
    }
    if (step === "personal_info") {
      return setStep('timing')
    }
    if(step === "timing"){
      return setStep("quote")
    }

    if(step === "quote") {
      return saveBooking()
    }
    setStep(prevState => prevState+1);
  }



  const renderButtons = useCallback( (disabled=false) => {
    if(step === "service" || step === "personal_info" || step === "timing" || step ==="quote"){
      return <div className="mt-5">
        <div className="fields-container d-flex justify-content-between">
          <Button
            type="normal"
            text="back"
            width="33%"
            height="40px"
            stylingMode="text"
            icon="spinprev"
            onClick={ () => {
              if(step === 'quote'){
                return setStep('timing')
              }
              if(step === 'timing'){
                return setStep('personal_info')
              }
              if(step === 'personal_info'){
                return setStep("service")
              }
            }}
            disabled={step === "service" || loadIndicatorVisible}
          />
          <Button
            ref={nextBtnRef}
            type="default"
            width="33%"
            height="40px"
            onClick={handleformValidation}
            disabled={loadIndicatorVisible}
          >
            <LoadIndicator
              height={20}
              width={20}
              className="button-indicator text-success me-2"
              visible={loadIndicatorVisible}
            />
            <span className="dx-button-text">next</span>
          </Button>
        </div>
      </div>
    }

  }, [step, formRef, loadIndicatorVisible])

  const renderProgressBar = useCallback(() => {
    return (
      <ProgressBar
        id="book-service-progress-bar"
        width="100%"
        minValue={0}
        maxValue={steps.length}
        className="mb-5"
        value={(steps.indexOf(step)+1)*100/steps.length}
        statusFormat={ () => {
          let template = ''
          switch(step){
            case "service":
              template = 'Service'
              break
            case "personal_info":
              template = 'Personal Info'
              break
            case "timing":
              template = 'Date and Time'
              break
            case "quote":
              template = 'Quote'
              break
            case "summary":
              template = 'Summary'
              break
            case "payment":
              template = 'Payment'
              break
            case "confirmed":
              template = 'Done'
              break
          }
          return  template
        }}
      />
    )
  }, [step])

  const renderBASbttn = useCallback(() => {
    navigate(`/book/${props.profileData?.company_profile}`)
    setFormData({})
    setStep(0)
  }, [])

  const renderErrorStep = useCallback( () => {
    return (
      <div className="text-center">
        <i className="dx-icon-errorcircle fs-2 me-2 text-danger"></i><span className="text-danger fs-2">Error</span>
        <h4 className=""><span className="fs-5 text-muted">{errorMessage}</span><br></br>

        </h4>
        <Button
          icon=""
          type="normal"
          text="Try again"
          stylingMode="outlined"
          height="40px"
          className="mt-4"
          onClick={() => {
            window.location.reload(false)
          }}
        />
      </div>
    )
  }, [])

  const dateOptions = {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric'
  }

  const handleConfirmBooking = useCallback( () => {
    if(stripeLink && appointmentId) {
      return window.open(`${stripeLink}?client_reference_id=${appointmentId}`, '_self')
    }
    saveBooking(unpaid.id)
  }, [formData, appointmentId])

  const renderFilledData = useCallback( () => {
    let selectedProvider
    let selectedFacility
    if(formData.provider_id && providers) {
      selectedProvider = providers.filter(p => formData.provider_id === p.resource_id)[0]
    }
    if(formData.facility_id && facilities) {
      selectedFacility = facilities.filter(p => formData.facility_id === p.resource_id)[0]
    }
    console.log(selectedProvider);
    console.log(selectedFacility);
    console.log(formData);
    if(formData) {
      return <>
        <hr></hr>
          <div>
            { formData.startDate && <>
              <p className="mb-0 text-muted">When:</p>
              <p className="mb-0 fw-bold">{new Date(formData.startDate).toLocaleDateString(undefined, dateOptions)}</p>
              <p className="mb-3">It should take about {getAppointmentPriceAndDuration(services, formData).duration} minutes.</p>
            </>}
            { formData.customer_data && formData.appointment_type && <>
              <p className="mb-0 text-muted">What services will be provided:</p> <p className="mb-3 fw-bold">{renderServiceTitles(services, formData)}</p>
            </>}
            { formData.customer_data && formData.customer_data.name && <>
              <p className="mb-0 text-muted">Customer:</p> <p className="mb-3 fw-bold">{formData.customer_data.name}</p>
            </>}

            { formData.customer_data && formData.customer_data.address && <>
              <p className="mb-0 text-muted">Customer Address:</p> <p className="mb-3 fw-bold">{formData.customer_data.address}</p>
            </>}

            { formData.provider_id && selectedProvider && <div className="d-flex flex-column mb-3 justify-content-start">
              <div className="d-flex flex-row justify-content-start align-items-start">
                {selectedProvider?.resource_details?.picture && <div
                  className="me-2 img-container"
                >
                <img src={selectedProvider?.resource_details?.picture} className="img-fluid"/>

                </div>}
                <div className="d-flex flex-column">
                  <p className="mb-0 text-muted">Who will help you:</p>
                  <p className="mb-0 fw-bold">{selectedProvider?.resource_details?.name}</p>
                </div>
              </div>
            </div>}

            { formData.facility_id && selectedFacility && <div className="d-flex flex-column mb-3">
              <div className="d-flex flex-row justify-content-start align-items-start">
                {selectedFacility?.resource_details?.picture && <div
                  className="me-2 img-container"
                >
                  <img src={selectedFacility?.resource_details?.picture} className="img-fluid"/>
                </div>}
                <div className="d-flex flex-column">
                  <p className="mb-0 text-muted">Where:</p>
                  <p className="mb-0 fw-bold">{selectedFacility?.resource_details?.name}</p>
                  {selectedFacility?.resource_details?.address && <p className="mb-0 "><a href={"http://maps.google.com/?q="+selectedFacility?.resource_details?.address} target="_">{selectedFacility?.resource_details?.address}</a></p>}
                  {selectedFacility?.resource_details?.description && <p className="mb-0 ">{selectedFacility?.resource_details?.description}</p>}
                  {selectedFacility?.resource_details?.phone && <p className="mb-0 ">Phone: <a href={"tel:"+selectedFacility?.resource_details?.phone} target="_">{selectedFacility?.resource_details?.phone}</a></p>}
                </div>
              </div>
            </div>}
            {prices && (prices.quote > 0) && (prices.quote !== prices.due) && <p className="mb-0 "><span className="text-muted">Quoted price: </span><span className="fw-bold">${prices.quote}</span>+tax</p>}
            {prices && prices.paid > 0 && <p className="mb-0 "><span className="text-muted">Amount paid: </span><span className="fw-bold">${prices.paid}</span></p>}
            {prices && prices.due > 0 && <p className="mb-0 "><span className="text-muted">Amount due: </span><span className="fw-bold">${prices.due}</span>+tax</p>}
          </div>
      </>
    }
  }, [formData, providers, facilities, prices, services])

  const ResourceSelectField = (e) => {
    // const available = isResourceAvailable(e, availableResources)
    const available = false
    let defaultImage = `url("${e.data?.resource_details.picture?e.data.resource_details.picture:'/DefaultAvatar.png'}")`
    if(e.data?.resource_type == 'facility') {
      defaultImage = `url("${e.data?.resource_details.picture?e.data.resource_details.picture:'/DefaultFacility.png'}")`
    }
    return (
      <div className="d-flex flex-row align-items-center">
        {e.data && <div
          className="ms-3"
          style={{
            backgroundColor: "#fff",
            backgroundImage: defaultImage,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            width: "50px",
            height: "43px",
            marginTop: "3px",
            marginBottom: "3px",
            borderRadius: "50%"
          }}
        />}
        <TextBox
          className="driver-select-field-name"
          placeholder={`Select...`}
          defaultValue={e.data?.resource_details.name}
          readOnly={true}
        />
          {/* { !available && <i className="fa fa-circle text-warning"></i> } */}
      </div>
    );
  }

  const ResourceSelectItem = useCallback( (e) => {
    // const available = isResourceAvailable(e, availableResources)
    const available = true
    let defaultImage = `url("${e.data && e.data.resource_details.picture?e.data.resource_details.picture:'/DefaultAvatar.png'}")`
    if(e.data.resource_type == 'facility') {
      defaultImage = `url("${e.data && e.data.resource_details.picture?e.data.resource_details.picture:'/DefaultFacility.png'}")`
    }
    return (
      <div className="d-flex flex-row align-items-center">
        <div
          style={{
            backgroundColor: "#fff",
            backgroundImage: defaultImage,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            width: "50px",
            height: "43px",
            marginTop: "3px",
            marginBottom: "3px",
            borderRadius: '50%'
          }}
        />

        <div className="d-flex align-items-center justify-content-between w-100">
          { e.data && <div className="ms-3">{e.data.resource_details.name}</div> }
          {/* { !available && <i className="fa fa-circle text-warning"></i> } */}
        </div>

      </div>
    );
  }, [])


  if(step===0){
    return <>
      <div>
        {props.profileData && <div className={'d-flex flex-column align-items-center'}>
          { props.profileData && props.profileData.image &&
            <div className={'form-avatar m-0'}>
              { props.profileData && props.profileData.image ?
                <img
                  alt={''}
                  src={props.profileData.image}
                /> :
                <img
                  alt={''}
                  src={props.profileData.image}
                />
              }
            </div>
          }

          <h4 className="my-3 text-center">{props.profileData.company_name?props.profileData.company_name:"Add Company Name"}</h4>

          <Button
            type="default"
            text={props.profileData.cta_text?props.profileData.cta_text:"Book"}
            height="40px"
            className="mt-3 px-1"
            onClick={() => {
              if (props.profileData.scheduling_settings?.cancelation_policy_text) {
                setStep("cancelation_policy")
              } else {
                setStep("service")
              }
            }}
          />

        </div>}
      </div>

  </>
  }

  if(step==="cancelation_policy"){
    return <>
      <h5 className="mb-4 text-center">Cancelation Policy</h5>
      <p className="cancelation_policy dx-theme-accent-as-text-color">{props.profileData.scheduling_settings.cancelation_policy_text}</p>
      <Button
        icon=""
        type="default"
        text="Agree & Continue"
        height="40px"
        className="mt-4"
        onClick={() => {
          setStep("service")
        }}
      />
    </>
  }


return <>
    { renderProgressBar() }

    {step==="quote" &&
      renderServicesInfo()
    }

    <Form
      id="form"
      ref={formRef}
      formData={formData}
    >
      {services && <Item
        dataField="appointment_type"
        editorType="dxTagBox"
        isRequired={true}
        editorOptions={{
          dataSource: services,
          valueExpr: 'service_id',
          displayExpr: 'details.service_title',
          placeholder: false,
          disabled: false,
          fieldTemplate: 'at_select_field',
          itemTemplate: 'at_select_item',
          onItemClick(e){
            if(!multipleServiceSelection) {
              formRef.current?.instance.updateData('appointment_type', [e.itemData.service_id])
            }
          },
          onValueChanged(e) {
            let Facilities = []
            let Providers = []
            for (let s of e.value) {
              const selService = services.find(i => i.service_id == s)
              if (selService.details.default_facility) {
                Facilities.push(selService.details.default_facility)
              }
              if (selService.details.default_service_provider) {
                Providers.push(selService.details.default_service_provider)
              }
            }

            if (Facilities.length > 0) {
              const sameFacility = Facilities.every( (val, i, arr) => val === arr[0] )
              if (sameFacility) {
                formRef.current?.instance.updateData('facility_id', Facilities[0])
              } else {
                formRef.current?.instance.updateData('facility_id', defaultFacility.resource_id)
              }
            } else {
              formRef.current?.instance.updateData('facility_id', '')
            }
            
            if (!isFacilitySettingOn && showCustomerAddress) {
              formRef.current?.instance.updateData('facility_id', "00000000-0000-0000-0000-000000000001")
            }
            
            if (Providers.length > 0) {
              const sameFacility = Providers.every( (val, i, arr) => val === arr[0] )
              if (sameFacility) {
                formRef.current?.instance.updateData('provider_id', Providers[0])
              } else {
                formRef.current?.instance.updateData('provider_id', '00000000-0000-0000-0000-000000000000')
              }
            } else {
              formRef.current?.instance.updateData('provider_id', '')
            }         
          },
          dropDownOptions: {
            height: (services.length*76+20).toString()+"px"
          },
        }}
        visible={step==="service"}
      >
        <Label render={LabelTemplate('dx-icon dx-icon-add', 'Add Service')} />
      </Item>}
      {facilities && <Item
        dataField="facility_id"
        editorType="dxSelectBox"
        isRequired={false}
        editorOptions={{
          items: facilities,
          dropDownOptions: {
            height: (facilities.length*76+20).toString()+"px"
          },
          valueExpr: 'resource_id',
          displayExpr: 'resource_details.name',
          placeholder: false,
          fieldTemplate: 'resource_select_field',
          itemTemplate: 'resource_select_item'
        }}
        visible={ !!(step==="service" && isFacilitySettingOn && facilities.length > 1) }
      >
        <Label render={LabelTemplate('far fa-building', 'Select Facility')} />
      </Item>}
      {providers && <Item
        dataField="provider_id"
        editorType="dxSelectBox"
        isRequired={false}
        editorOptions={{
          items: providers,
          valueExpr: 'resource_id',
          displayExpr: 'resource_details.name',
          placeholder: false,
          dropDownOptions: {
            height: (providers.length*76+20).toString()+"px"
          },
          fieldTemplate: 'resource_select_field',
          itemTemplate: 'resource_select_item',
        }}
        visible={ !!(step==="service" && isServiceProviderSettingOn) }
      >
        <Label render={LabelTemplate('dx-icon dx-icon-group', 'Select Team Member')} />
      </Item>}
      <Item
        dataField="startDate"
        editorType="dxDateBox"
        isRequired={true}
        editorOptions={{
          min: minCalendarTime,
          max: customizeEndCalendarDate(12),
          dateOutOfRangeMessage: 'Cannot add entries in the past, or close to current time',
          type: 'datetime',
          disabledDates: getDisabledDates(props.profileData?.scheduling_settings, 365),
          disabled: false,
        }}
        visible={step==="timing" && !!!showAvailability2Public}
      >
        <Label render={LabelTemplate('dx-icon dx-icon-event', 'Appointment Date and Time')} />
      </Item>

      <Item
        dataField="date"
        editorType="dxDateBox"
        isRequired={true}
        editorOptions={{
          min: minCalendarTime,
          max: customizeEndCalendarDate(12),
          dateOutOfRangeMessage: 'Cannot add entries in the past, or close to current time',
          type: 'date',
          disabledDates: getDisabledDates(props.profileData?.scheduling_settings, 365),
          placeholder: 'Select date...',
          disabled: false,
          onValueChanged(){
            getAvailableServiceHours()
          }
        }}
        visible={step==="timing" && !!showAvailability2Public}
      >
        <Label render={LabelTemplate('dx-icon dx-icon-event', 'Appointment Date')} />
      </Item>
      <Item
        dataField="startDate"
        editorType="dxSelectBox"
        isRequired={true}
        editorOptions={{
          dataSource: availableServiceTimes,
          disabled: isTimeFieldDisabled,
          placeholder: 'Select hour...',
          fieldTemplate: 'time_select_field',
          itemTemplate: 'time_select_item',
          onValueChanged(e){
            const isoDate = e.value
            const startDate = new Date(isoDate)
            formRef.current?.instance.updateData('startDate', startDate)
          }
        }}
        visible={step==="timing" && !!showAvailability2Public}
      >
        <Label render={LabelTemplate('dx-icon dx-icon-clock', 'Appointment Time')} />
      </Item>
      <Item
        dataField="customer_data.name"
        editorType="dxTextBox"
        isRequired={true}
        visible={step==="personal_info"}
        accessKey="customer_data.name"
        editorOptions={{
          accessKey: "customer_data.name",
          onValueChanged(e){
            formRef.current?.instance.updateData('customer_data.name', e.value.trim())  
          }
        }}
      >
        <Label render={LabelTemplate('dx-icon dx-icon-user', 'Name')} />
      </Item>
      <Item
        dataField="customer_data.phone"
        editorType="dxTextBox"
        editorOptions={{
          mask: '(000) 000-0000'
        }}
        isRequired={true}
        visible={step==="personal_info"}
        id="customer_data.phone"
      >
        <Label render={LabelTemplate('dx-icon dx-icon-tel', 'Phone')} />
      </Item>
      <Item
        dataField="customer_data.email"
        editorType="dxTextBox"
        isRequired={true}
        visible={step==="personal_info"}
        id="customer_data.email"
        editorOptions={{
          activeStateEnabled: true,
          onValueChanged(e){
            formRef.current?.instance.updateData('customer_data.email', e.value.trim())  
          }
        }}
      >
        <RequiredRule message="Email is required" />
        <EmailRule message="Email is invalid" />
        <Label render={LabelTemplate('dx-icon dx-icon-email', 'Email')} />
      </Item>
      <Item
        dataField="customer_data.address"
        editorType="dxAutocomplete"
        isRequired={true}
        visible={step==="personal_info" && showCustomerAddress}
        id="customer_data.address"
        editorOptions={{
          showDropDownButton: true,
          activeStateEnabled: true,
          valueExpr: 'description',
          dataSource: predictionResults,
          onInput(e) {
            fetchPredictions(e.event.currentTarget.value, formRef);
          },
        }}
      >
        <RequiredRule message="Address is required" />
        <Label render={LabelTemplate('dx-icon dx-icon-pinmap', 'Address')} />
      </Item>
      <Item
        dataField="details.notes"
        editorType="dxTextArea"
        visible={step==="personal_info"}
        editorOptions={{
          placeholder: 'Add all things worth to mention',
          disabled: false
        }}
      >
        <Label render={LabelTemplate('dx-icon dx-icon-info', 'Special Requests')} />
      </Item>

      <Template name="at_select_field" component={ApptTypeField} />
      <Template name="at_select_item" component={ApptTypeItem} />
      <Template name="resource_select_field" component={ResourceSelectField} />
      <Template name="resource_select_item" component={ResourceSelectItem} />
      <Template name="time_select_field" component={TimeField} />
      <Template name="time_select_item" component={TimeItem} />
    </Form>


    { step === "summary" && <>
        <div className="text-center">
          <h4 className="">
            <span className="fs-5 text-muted">Your booking is</span>

            <br></br>
            <span className="">{pending.text}</span>
          </h4>
          <p>Upon availability confirmations you will hear from us!</p>
        </div>
        { renderFilledData() }

        <div className="fields-container d-flex flex-column justify-content-between mt-4">
          <Button
            icon="close"
            type="danger"
            text="Cancel"
            stylingMode="outlined"
            height="40px"
            className="mb-3"
            onClick={() => {
                let result = confirm("<i>Are you sure?</i>", "Confirm deletion");
                result.then(dialogResult => {
                  if(dialogResult) {
                    onApptDeletion()
                  }
                }
                )
              }
            }
            disabled={loadIndicatorVisible}
          />

          <Button
            icon="add"
            type="normal"
            text={"Book another service"}
            height="40px"
            onClick={renderBASbttn}
            disabled={loadIndicatorVisible}
          />
        </div>
      </>
    }

    { step === "payment" && <>
          <div className="text-center">
            <h4 className=""><span className="fs-5 text-muted">Your booking is</span><br></br>
              <span className="text-success"><i className="fa fa-check fs-2 me-2 text-success"></i>{available.text}</span>
            </h4>


          </div>
          { renderFilledData() }
          { renderMarkServiceAsCompletedBttn() }
          {!isProvider && <Button
            type="default"
            className="mt-5"
            width="100%"
            height="40px"
            onClick={ handleConfirmBooking }
            disabled={loadIndicatorVisible}
          >
            <LoadIndicator
              height={20}
              width={20}
              className="button-indicator text-success me-2"
              visible={loadIndicatorVisible}
            />
            <span className="dx-button-text"> { stripeLink ? `Pay to Confirm your booking*` : 'Confirm Your Booking' } </span>
          </Button>}

          {!isProvider && <Button
            icon="close"
            type="danger"
            text="Cancel"
            stylingMode="outlined"
            height="40px"
            className="mt-4"
            onClick={() => {
                let result = confirm("<i>Are you sure?</i>", "Confirm deletion");
                result.then(dialogResult => {
                  if(dialogResult) {
                    onApptDeletion()
                  }
                }
                )
              }
            }
            disabled={loadIndicatorVisible}
          />}
          {stripeLink && !isProvider && <p className="pt-3">* To confirm your booking please proceed to the payment page by clicking the button above.
            The amount you pay to confirm your reservation is deducted from your final amount.</p>}
        </>
    }

    { step === 'confirmed' && formData.status === paid.id && <>
        <div className="text-center">
          <h4 className=""><span className="fs-5 text-muted">{ isProvider ? 'The' : 'Your' } booking is</span><br></br>
            <span className="text-success"><i className="fa fa-check-circle fs-2 me-2"></i>{paid.text}</span>
          </h4>
          {/* <p>Enjoy your ride with us!</p> */}
        </div>
        { renderFilledData() }
        { renderMarkServiceAsCompletedBttn() }
      </>
    }

    { step === "confirmed" && formData.status === unpaid.id && <>
        <div className="text-center">
          <h4 className=""><span className="fs-5 text-muted">{ isProvider ? 'The' : 'Your' } booking is</span><br></br>
            <span className="text-success"><i className="fa fa-check-circle fs-2 me-2"></i>{stripeLink ? unpaid.text : 'Confirmed'}</span>
          </h4>
          {/* <p>Enjoy your ride with us!</p> */}
        </div>
        { renderFilledData() }
        { renderMarkServiceAsCompletedBttn() }
      </>
    }

    { step === 'rejected' && <>
        <div className="text-center">
          <h4 className=""><span className="fs-5 text-muted">Your booking is</span><br></br>
            <span className="text-danger"><i className="fas fa-ban fs-2 me-2"></i>{rejected.text}</span>
          </h4>
        </div>
        { renderFilledData() }
        <div className="fields-container d-flex justify-content-center mt-4">
          <Button
            icon="add"
            type="normal"
            text="Book another service"
            height="40px"
            onClick={renderBASbttn}
            disabled={loadIndicatorVisible}
          />
        </div>
      </>
    }
    { step === 'canceled' && <>
        <div className="text-center">
          <h4 className=""><span className="fs-5 text-muted">Your booking is</span><br></br>
            <span className="text-warning"><i className="dx-icon-clearcircle fs-2 me-2"></i>{canceled.text}</span>
          </h4>
        </div>
        { renderFilledData() }

        <div className="fields-container d-flex justify-content-center mt-4">
          <Button
            icon="add"
            type="normal"
            text="Book another service"
            height="40px"
            onClick={renderBASbttn}
            disabled={loadIndicatorVisible}
          />
        </div>
      </>
    }
     { step === completed.id && <>
        <div className="text-center">
          <h4 className=""><span className="fs-5 text-muted">{ isProvider ? 'The' : 'Your' } booking is</span><br></br>
            <span className="text-success"><i className="fa fa-check-circle fs-2 me-2"></i>{completed.text}</span>
          </h4>
        </div>
        { renderFilledData() }

        <Button
          icon="add"
          type="normal"
          text="Book another service"
          height="40px"
          className="mt-4"
          onClick={renderBASbttn}
          disabled={loadIndicatorVisible}
          visible={!isProvider}
        />
      </>
    }


    { step === 'error' && renderErrorStep() }

    {renderButtons()}
  </>

}


const ApptTypeField = (e) => {
  return (
    <div className="d-flex flex-row align-items-center">
      <TextBox
        placeholder={ e.data.length === 0 ? "Select an entry type ..." : "" }
        readOnly={true}
      />
    </div>
  );
}

const ApptTypeItem = (e) => {
  const picture = e.data.details.picture
  const title = e.data.details.service_title
  const description = e.data.details.description
  const price = e.data.details.price

  return (
      <div className="d-flex flex-row align-items-center justify-content-between gap-3 mb-1">
        <div
          className="w-10"
          style={{
            backgroundImage: `url("${picture ? picture : '/DefaultAvatar.png'}")`,
            backgroundSize: 'cover',
            width: "15%",
            aspectRatio: 1,
            borderRadius: "50%",
          }}
        />
        <div className="d-flex flex-column me-1" style={{width: '85%'}}>
        <div className={`d-flex justify-content-between ${description && `border-bottom border-1`}`}>
          <span className="fs-lg">{title}</span>
          {!!price && <span className="text-muted">${price}</span>}
        </div>
          <div className="text-muted text-muted text-wrap font-weight-light mt-1 service-description">{description}</div>
        </div>
      </div>
  );
}

const TimeField = (e) => {
  const formattedTime = parseHours(e.data)

  return (
      <TextBox
        placeholder={ "Select hour..." }
        readOnly={true}
        value={formattedTime}
      />
  );
}

const TimeItem = (e) => {
  return parseHours(e.data)
}

export default BookService
