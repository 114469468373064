import Profile from './Profile/Profile'
import SchedulingSettings from './SchedulingSettings/SchedulingSettings'

const Settings = () => {
  return <>
    <Profile />
    <SchedulingSettings />
  </>
}

export default Settings