import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useSearchParams } from 'react-router-dom'
import { useNavigate } from 'react-router';
import 'devextreme/data/odata/store';
import FileUploader from 'devextreme-react/file-uploader';
import { LoadIndicator } from 'devextreme-react/load-indicator';
import devices from 'devextreme/core/devices';
import { debounce } from '../../utils/miscUtil';

import DataGrid, {
  Column,
  Pager,
  Paging,
  FilterRow,
  Editing,
  Button as DGButton,
  Lookup,
} from 'devextreme-react/data-grid';
import Button from 'devextreme-react/button';
import {Form,
    Label,
    SimpleItem,
    Item,
    EmptyItem,
    GroupItem,
    ButtonItem,
    RequiredRule,
    EmailRule} from 'devextreme-react/form';
import { useAuth } from '../../contexts/auth';
import {getResources, updateResource, deleteResource, getPublicServices} from '../../api/api'
import {uploadFileChunk} from '../../api/s3'
import { Popup, Position, ToolbarItem } from 'devextreme-react/popup';

const isPhone = devices.current().deviceType === 'phone'

export default function Providers() {
  const { user, refreshSession } = useAuth();
  const [providerList, setProviderList] = useState([]);
  const [facilityList, setFacilityList] = useState([]);
  const [services, setServices] = useState()
  const [currentProvider, setCurrentProvider] = useState(null);
  const [loading, setLoading] = useState(true)
  const fileExtensions = ['.jpg', '.jpeg', '.png'];
  const formRef = useRef(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();


  useEffect(() => {
    if (user.signInUserSession) {
      getResources(user, onGetResourcesSuccess, onError);
      getPublicServices(user.username, data => {console.log('services: ', data.services); setServices(data.services)}, err => console.log((err)))
    }
  }, [user && user.signInUserSession, user.signInUserSession.idToken.payload]);

  useEffect(() => {
    if (currentProvider) {
      setCancelButtonOptions({
        disabled: false,
        text: 'Cancel',
        width: '100%',
        onClick: onCancel,
      });
      setSaveButtonOptions({
        disabled: !(currentProvider.resource_details.name && currentProvider.resource_details.email && currentProvider.resource_details.phone),
        text: 'Save',
        type: 'default',
        width: '100%',
        onClick: onSave,
      });
    }
  }, [currentProvider]);


  const onSave = async () => {
    setSaveButtonOptions({
      disabled: true,
      text: 'Save',
      type: 'default',
      width: '100%',
      onClick: onSave,
    });
    updateResource(user, currentProvider, onSaveSuccess, onError);
  }

  const onCloseDriverDialog = () => {
    setCurrentProvider(null);
  }

  const onCancel = () => {
    setCurrentProvider(null);
  }

  const [cancelButtonOptions, setCancelButtonOptions] = useState({
    disabled: false,
    text: 'Cancel',
    width: '100%',
    onClick: onCancel,
  });

  const [saveButtonOptions, setSaveButtonOptions] = useState({
    disabled: false,
    text: 'Save',
    type: 'default',
    width: '100%',
    onClick: onSave,
  });

  const onError = useCallback((error) => {
    if (error.status === 401) {
      refreshSession();
    } else {
      console.log(error);
    }
  }, [refreshSession]);

  const onSaveSuccess = useCallback((data) => {
    getResources(user, onGetResourcesSuccess, onError);
    setCurrentProvider(null);
    if (searchParams.get('setupMode')) {
      navigate('/home?setupMode=true');
    }
  }, [])

  const onGetResourcesSuccess = useCallback((data) => {
    let lst = []
    let lst2 = []
    for (let i = 0; i < data.length; i++) {
      if (data[i].resource_type === 'provider') {
        lst.push(data[i])
      }
      if (data[i].resource_type === 'facility') {
        lst2.push(data[i])
      }
    }
    setProviderList(lst);
    setFacilityList(lst2)
    setLoading(false);
    if ((searchParams.get('setupPopup') === 'add') && (lst.length ===0)){
      onAdd();
    }
  }, [])

  const onUploadChunk = (file, uploadInfo) => {
    return uploadFileChunk(file, uploadInfo, {path:'images/'+currentProvider.resource_id});
  }

  const onUploaded = (e) => {
    let data = {...currentProvider};
    if (data === null) {
      data = {}
    }
    data.resource_details.picture = "https://s3.us-east-2.amazonaws.com/com.wdttx.scheduler/images/"+currentProvider.resource_id+"/"+encodeURIComponent(e.file.name).replace(/%20/g,'+');
    setCurrentProvider(data);
  }

  const onSavingDatagrid = (e) => {
    for (let i = 0; i < e.changes.length; i++) {
      if (e.changes[i].type === "remove") {
        deleteResource(user, e.changes[i].key, onSaveSuccess, onError);
      }
    }
  }

  const cellRender = (data) => {
    return (
      <div
        style={{
          backgroundImage: `url("${data.value?data.value:'/DefaultAvatar.png'}")`,
          backgroundSize: 'cover',
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          width: '100%',
          aspectRatio: 1,
          borderRadius: '50%'
        }}
      />

    );
  }


  const onFormDataChanged = (e) => {
    setSaveButtonOptions({
      disabled: !(currentProvider.resource_details.name && currentProvider.resource_details.email && currentProvider.resource_details.phone),
      text: 'Save',
      type: 'default',
      width: '100%',
      onClick: onSave,
    });
    //console.log(e);
  }

  const phoneEditorOptions = { mask: '(000) 000-0000' };

  const onAdd = () => {
    setCurrentProvider(
      {
        resource_id: crypto.randomUUID(),
        resource_details: {},
        resource_type: 'provider',
      });
  }

  if(!loading) {
    return (
      <React.Fragment>
        <div className="d-flex flex-row justify-content-between align-items-center content-block">
          <h2 className={''}>Team</h2>
          <Button
            type="default"
            text="Add Team Member"
            onClick={onAdd}
          />
        </div>


        <DataGrid
          className={'dx-card wide-card'}
          dataSource={providerList}
          showBorders={false}
          focusedRowEnabled={false}
          defaultFocusedRowIndex={0}
          columnAutoWidth={true}
          columnHidingEnabled={true}
          onRowUpdating={() => console.log('RowUpdating')}
          onRowUpdated={() => console.log('RowUpdated')}
          onSaving={onSavingDatagrid}
        >
          <Paging defaultPageSize={50} />
          <Pager showPageSizeSelector={true} showInfo={true} />
          { false && <FilterRow visible={true} />}

          <Column
            dataField={'resource_details.picture'}
            width={80}
            caption={'Picture'}
            hidingPriority={4}
            cellRender={cellRender}
          />
          <Column
            dataField={'resource_details.name'}
            caption={'Name'}
            hidingPriority={3}
          />
          <Column
            dataField={'resource_details.email'}
            caption={'Email'}
            hidingPriority={1}
          />
          <Column
            dataField={'resource_details.phone'}
            caption={'Phone'}
            hidingPriority={0}
          />
          <Column type="buttons">
            <DGButton
                text="Edit"
                icon="fas fa-pen fs-6"
                hint="Edit"
                onClick={(e) => {
                  setCurrentProvider(e.row.data);
                }}
            />
            <DGButton name="delete" />
          </Column>
          <Editing
            allowDeleting={true}
            mode="popup">
          </Editing>
        </DataGrid>

        <Popup
          className="text-danger"
          visible={currentProvider !== null}
          onHidden={onCloseDriverDialog}
          dragEnabled={false}
          showCloseButton={false}
          showTitle={true}
          fullScreen={isPhone}
          title={currentProvider&&currentProvider.owner_id?"Edit Team Member":"Add Team Member"}
          container=".dx-viewport"
          width={380}
          height={720}
        >
          <Position at="center" my="center"collision="fit" />
          <ToolbarItem
            widget="dxButton"
            toolbar="bottom"
            location="before"
            options={saveButtonOptions}
          />
          <ToolbarItem
            widget="dxButton"
            toolbar="bottom"
            location="after"
            options={cancelButtonOptions}
          />

          {currentProvider && <div className="">

            <div className={'d-flex flex-column align-items-center'}>
              <div className={'text-center form-avatar'}>
                {(currentProvider.resource_details && currentProvider.resource_details.picture) ? <div
                  style={{
                    backgroundImage: `url(${currentProvider.resource_details.picture})`,
                    backgroundSize: 'cover',
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                    width: '100%',
                    aspectRatio: 1,
                    borderRadius: '50%'
                  }}
                />:
                <img
                  alt={''}
                  src={'/DefaultAvatar.png'}
                />}
              </div>
              <div className="d-flex text-center" style={{width: "170px"}}>
                <FileUploader
                  selectButtonText="Update Image"
                  labelText=""
                  multiple={false}
                  chunkSize={6000000}
                  uploadChunk={onUploadChunk}
                  onUploaded={onUploaded}
                  allowedFileExtensions={fileExtensions}
                />
              </div>

            </div>
            <Form
              ref={formRef}
              id={'form'}
              formData={currentProvider}
              labelLocation={'top'}
              onFieldDataChanged={onFormDataChanged}
            >
              <SimpleItem dataField="resource_details.name">
                <Label text="Team Member Name (Publicly Visible)"/>
                <RequiredRule message="Team Member name is required" />
              </SimpleItem>
              <SimpleItem 
                dataField="resource_details.email" 
                editorOptions={{
                  onInput: debounce( (e) => {
                    const val = e.event.currentTarget.value.trim()
                    console.log('val: ', val.trim())
                    formRef.current.instance.updateData('resource_details.email', '')
                    formRef.current.instance.updateData('resource_details.email', val)
          
                    // formRef.current.instance.option().formData.startDate
                    console.log('formRef: ', formRef.current.instance.option().formData.resource_details)
                  }, 100)
                }}
              >
                <Label text="Email"/>
                <RequiredRule message="Email is required" />
                <EmailRule message="Email is invalid" />
              </SimpleItem>
              <SimpleItem
                  dataField="resource_details.phone"
                  editorOptions={phoneEditorOptions}
              >
                <Label text="Phone"/>
                <RequiredRule message="Phone is required" />
              </SimpleItem>

              <Item
                  dataField="resource_details.appointment_type"
                  editorType="dxTagBox"
                  editorOptions={{
                    dataSource: services,
                    displayExpr: 'details.service_title',
                    valueExpr: 'service_id',
                  }}
              >
                <Label text="Services which Team Member is Offering"/>
              </Item>
              <Item
                  dataField="resource_details.facilities"
                  editorType="dxTagBox"
                  editorOptions={{
                    dataSource: facilityList,
                    displayExpr: 'resource_details.name',
                    valueExpr: 'resource_id',
                  }}
              >
                <Label text="Services Facilities used by Team Member"/>
              </Item>

            </Form>
          </div>}
        </Popup>

      </React.Fragment>
    )
  } else {
    return (
      <div className="d-flex flex-row justify-content-center align-items-center mt-5 pt-5">
          <LoadIndicator
              id="large-indicator"
              height={60}
              width={60}
          />
      </div>
    )
  }

}
