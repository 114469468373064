import { useState, useEffect, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAuth } from '../../contexts/auth';
import { getResources, getAppointments, inviteDriversToAppointment } from '../../api/api'
import { LoadIndicator } from 'devextreme-react/load-indicator';
import {
    DataGrid, Editing, Column, Sorting, Button
} from 'devextreme-react/data-grid';
import { renderServiceTitles } from '../../utils/miscUtil'
// import { Button } from 'devextreme-react/button';
import devices from 'devextreme/core/devices';
import notify from 'devextreme/ui/notify';
import { injectDataInAppointment } from "../../utils/miscUtil";

const isPhone = devices.current().deviceType === 'phone'


const Tasks = (props) => {
    const { user, refreshSession } = useAuth();
    const [pendingAppts, setPendingAppts] = useState([])
    const [pendingAirportRoundtripAppts, setPendingAirportRoundtripAppts] = useState([])
    const [noFacilityAppts, setNoFacilityAppts] = useState([])
    const [resources, setResources] = useState([])
    const [appointmentData, setAppointmentData] = useState([])

    const [noProviderAppts, setNoProviderAppts] = useState([])
    const [availableAppts, setAvailableAppts] = useState([])
    const [loading, setLoading] = useState(true)
    const navigate = useNavigate()


    useEffect(() => {
        if (user.signInUserSession) {
          getAppointments(user, onGetAppointmentsSuccess, err => console.log(err));
          getResources(user, onGetResourcesSuccess, err => console.log(err));
        }
      }, [user && user.signInUserSession, user.signInUserSession.idToken.payload]);

    const onGetResourcesSuccess = (r) => {
      console.log(r);
      setResources(r);
    }

    const onGetAppointmentsSuccess = useCallback( (data) => {
      setAppointmentData(data);
    }, [props])

    const navigateToScheduler = useCallback( apptId => {
        navigate('/schedule?apptId='+apptId)
    }, [])

    const dispayApptTitle = useCallback(val => {
        if(props.services) {
            return renderServiceTitles(props.services, val)
        }
    }, [props.services])

    useEffect(() => {
      if (props.services) {
        for (let j = 0; j<appointmentData.length; j++ ) {
          injectDataInAppointment(appointmentData[j], resources, props.services)
        }

        let data = appointmentData.filter(item => new Date(item.startDate) > new Date()) // exclude past appointmnts
        data = data.filter(item => item.status !== 'canceled' && item.status !== 'rejected') // exclude canceled and rejected appointments
        const availableRides = data.filter(item => item.status === 'available')
        console.log(data.filter(item => (item.status === 'pending')));
        setPendingAppts(data.filter(item => (item.status === 'pending')))
        const noFacilityAppts = data.filter(item => item.facility_id === '00000000-0000-0000-0000-000000000000' && item.status !== 'pending')
        const noProviderAppts = data.filter(item => item.provider_id === '00000000-0000-0000-0000-000000000000' && item.status !== 'pending')
        setNoFacilityAppts(noFacilityAppts)
        setNoProviderAppts(noProviderAppts)
        setAvailableAppts(availableRides)
        setLoading(false)
      }
    }, [props.services, resources, appointmentData]);

    const renderDataGrid = useCallback( (data, driver=false) => {
        return <>
            <DataGrid
                dataSource={data}
                readOnly={true}
                // onRowClick={navigateToScheduler}
                showColumnHeaders={false}
                showSortIndexes={true}
                allowColumnResizing={true}
                columnHidingEnabled={true}
                width='100%'
                columnAutoWidth={true}
            >
                <Sorting mode="single" />
                <Column
                    dataField="appointment_type"
                    caption="Entry Type"
                    calculateDisplayValue={dispayApptTitle}
                    visibleIndex={1}
                    hidingPriority={3}
                    width="250px"
                />
                <Column
                    dataField="startDate"
                    caption="Start Date"
                    dataType="datetime"
                    defaultSortOrder="asc"
                    visibleIndex={2}
                    width="180px"
                />
                <Column
                    type="buttons"
                    alignment="right"
                    visibleIndex={5}
                >
                    <Button
                        hint="Invite team members"
                        icon={'fa fa-share fs-6 me-2'}
                        visible={driver}
                        onClick={ e => {
                            inviteDriversToAppointment(user, e.row.key.appointment_id,
                                data => { notify("Invitation sent", 'success', 3000); },
                                err => {
                                    notify("Something went wrong. Please refresh and try again.", 'error', 3000);
                                    console.log(err);
                                }
                            )
                        }}
                    >
                        {/* { !isPhone && <span className='fw-medium mx-1'>INVITE</span> } */}
                    </Button>
                    <Button
                        hint="Manage appointment"
                        icon="edit"
                        onClick={ e => navigateToScheduler(e.row.key.appointment_id) }
                    >
                        {/* { isPhone ? <i class="fas fa-pen mx-1"></i> : <span className='fw-medium mx-1'>MANAGE</span> } */}
                    </Button>

                </Column>

        </DataGrid>
        </>
    }, [props.services])

    if(!loading) {
        return (
            <div className="content-block mt-5">
                <h2>Tasks</h2>
                <h3 className="fs-6 mt-4">Pending Appointments</h3>
                <p>It requires your attention because it is submitted. You need to mark it as available in order for the customer to be able to proceed</p>
                { renderDataGrid(pendingAppts) }
                <h3 className="fs-6 mt-4">Missing Facility</h3>
                { renderDataGrid(noFacilityAppts) }
                <h3 className="fs-6 mt-4">Missing Team Member</h3>
                { renderDataGrid(noProviderAppts, true) }
                <h3 className="fs-6 mt-4">Unconfirmed Appointments</h3>
                { renderDataGrid(availableAppts) }
            </div>
        )
    } else {
        return (
            <div className="d-flex flex-row justify-content-center align-items-center mt-5 pt-5">
                <LoadIndicator
                    id="large-indicator"
                    height={60}
                    width={60}
                />
            </div>
        )
    }
}


export default Tasks
