import React, { useState, useEffect, useRef, useCallback } from 'react';
import 'devextreme/data/odata/store';
import './Scheduler.scss'
import { ScheduleResourceCell, ScheduleDataCell, ScheduleDataTooltip } from '../../components';
import { Scheduler , Resource, Editing, View } from 'devextreme-react/scheduler';
import { Autocomplete } from 'devextreme-react/autocomplete';
import { Template } from 'devextreme-react/core/template';
import ContextMenu, { ContextMenuTypes } from 'devextreme-react/context-menu';
import TextBox from 'devextreme-react/text-box';
import devices from 'devextreme/core/devices';
import notify from 'devextreme/ui/notify';
import { useAuth } from '../../contexts/auth';
import { confirm } from "devextreme/ui/dialog"
import { getAvailableResources, getAppointment } from '../../api/api'
import { injectDataInAppointment } from "../../utils/miscUtil";
import { useMap, useMapsLibrary } from '@vis.gl/react-google-maps';

const appointmentClassName = '.dx-scheduler-appointment';
const cellClassName = '.dx-scheduler-date-table-cell';
const isPhone = devices.current().deviceType === 'phone'
const zeroId = "00000000-0000-0000-0000-000000000000"

export default function WTDScheduler(props) {
  const schedulerRef = useRef(null);
  const contextMenuRef = useRef(null);
  const { user, refreshSession } = useAuth();

  const groups = props.settings.group_scheduler_by_provider ? ['provider_id'] : ['facility_id'];
  const showFacilityOption = props.settings.facility_option
  const showCustomerAddress = props.settings.show_customer_address_option

  let openingHour = 0
  let closingHour = 23
  if(props.settings.business_hours.opening){
    openingHour = new Date(props.settings.business_hours.opening).getHours()
  }
  if(props.settings.business_hours.closing){
    closingHour = new Date(props.settings.business_hours.closing).getHours()
  }
  if (openingHour > closingHour) {
    openingHour = 0
    closingHour = 23
  }

  let activeFacilities = props.facilityList.filter(facility => facility.resource_details.active)

  const [currentSchedulerDate, setCurrentSchedulerDate] = useState(new Date())
  const [availableResources, setAvailableResources] = useState()
  const [sessionToken, setSessionToken] = useState();
  
  let predictionResults = []
  let [autocompleteService, setAutocompleteService] = useState(null);

  const map = useMap();
  const places = useMapsLibrary('places');
  const routes = useMapsLibrary('routes');

  let selectedCustomer = {}

  const contextMenuItems = [
    {
      text: 'Add Entry',
      icon: 'fas fa-plus',
      visible: true,
    },
    {
      text: 'Edit',
      icon: 'fas fa-pen',
      visible: true,
    },
    {
      text: 'Delete',
      icon: "fas fa-trash-alt",
      visible: true,
    },
    {
      text: 'Invite Team Members',
      icon: 'fas fa-share',
      visible: true,
    }
  ];

  const formRef = useRef(null);


  useEffect(() => {
    console.log(props);
    if (props.appointmentData && props.showAppointment) {
      if (props.showAppointment.id) {
        const apptId =props.showAppointment.id
        const appt = props.appointmentData.filter(item => item.appointment_id === apptId)[0]
        if(!appt) {
          notify("Entry data not found. Please try again.", 'error', 3000);
          return;
        }
        if(apptId && schedulerRef.current) {
          setCurrentSchedulerDate(new Date(appt.startDate)) // reset Scheduler currentDate based on popup startDate
          schedulerRef.current.instance.showAppointmentPopup(appt)
        }
      } else {
        schedulerRef.current?.instance.showAppointmentPopup();
      }
    }
  }, [props.showAppointment, props.appointmentData]);

  useEffect(() => {
    if (!places || !routes) return;
    setAutocompleteService(new places.AutocompleteService())
    setSessionToken(new places.AutocompleteSessionToken());
  }, [map, places, routes]);

  const fetchPredictions = useCallback(
    async (inputValue, form) => {
      if (!autocompleteService || !inputValue) {
        while (predictionResults.length > 0) {
          predictionResults.pop();
        }
        return;
      }
      const request = {input: inputValue, sessionToken};
      const response = await autocompleteService.getPlacePredictions(request);

      while (predictionResults.length > 0) {
        predictionResults.pop();
      }
      for (let i = 0; i < response.predictions.length; i++) {
        predictionResults.push(response.predictions[i]);
      }
    }, [autocompleteService, sessionToken]
  )

  const inviteProvidersToAppointment = (appointmentData) => {
    props.onInvite(appointmentData);
  };

  const onAppointmentContextMenu = useCallback((event) => {
    const { appointmentData, targetedAppointmentData } = event;
    const scheduler = schedulerRef.current?.instance;
    let ctxItems = contextMenuRef?.current.instance.option('items')
    for (let i = 0; i < ctxItems.length; i++) {
      ctxItems[i].visible = false;
    }
    for (let i = 0; i < ctxItems.length; i++) {
      if (ctxItems[i].text === "Edit") {
        ctxItems[i].onItemClick = () => scheduler?.showAppointmentPopup(appointmentData);
        ctxItems[i].visible = true;
      }
      if (ctxItems[i].text === "Delete") {
        ctxItems[i].onItemClick = () => scheduler?.deleteAppointment(appointmentData);
        ctxItems[i].visible = true;
      }
      // if (ctxItems[i].text === "Invite Team Members") {
      //   ctxItems[i].onItemClick = () => inviteProvidersToAppointment(appointmentData);
      //   ctxItems[i].visible = true;
      // }
    }
    contextMenuRef?.current.instance.option('items', ctxItems);
    contextMenuRef?.current.instance.option('target', appointmentClassName);
  }, []);

  const onCellContextMenu = useCallback(
    (e) => {
      const scheduler = schedulerRef.current?.instance;
      let ctxItems = contextMenuRef?.current.instance.option('items')
      for (let i = 0; i < ctxItems.length; i++) {
        ctxItems[i].visible = false;
      }
      for (let i = 0; i < ctxItems.length; i++) {
        if (ctxItems[i].text === "Add Entry") {
          ctxItems[i].onItemClick = () => scheduler?.showAppointmentPopup({ startDate: e.cellData.startDate, facility_id: e.cellData.groups.facility_id }, true);
          ctxItems[i].visible = true;
        }
      }
      contextMenuRef?.current.instance.option('items', ctxItems);
      contextMenuRef?.current.instance.option('target', cellClassName);
    },
    [groups],
  );



  const fetchAvailableResources = useCallback( (user, apptData) => {
    return
    if(!apptData.startDate) return
    console.log('fetch available resource', apptData)
    getAvailableResources(
      user,
      apptData,
      r=> {
        console.log("got available resourses on appt form open", r)
        setAvailableResources(r)
      },
      err=>console.warn('did not get any available resources, ', err)
    )
  }, [])

  const onContextMenuItemClick = (e) => {
    if(e.itemData.text === 'Delete') {
      let result = confirm("<i>When deleting an appointment, no notifications will be sent. This operation cannot be undone. Are you sure you want to delete the appointment?</i>", "Attention!");
      result.then(dialogResult => dialogResult && e.itemData.onItemClick?.(e) )
    } else {
      e.itemData.onItemClick?.(e)
    }
  };

  const AppointmentMenuTemplate = (props) => (
    <div>
      {props.data.color && (
        <div
          className="item-badge"
          style={{ backgroundColor: props.data.color }}
        />
      )}
      <div className="item-template-container">
        {props.data.icon && <span className={`${props.data.icon} me-3 dx-icon`}></span>}
        <span className="dx-menu-item-text">{props.data.text}</span>
        {false && props.data.items && <span className="dx-icon-spinright dx-icon"></span>}
      </div>
    </div>
  );



  const setForm = useCallback( (form, entry_type) => {
    if (!(entry_type)) {
      return;
    }
    let items = form.option('items');

    for (let i = 0; i < items.length; i++) {
      if (!entry_type.includes(zeroId)) {  
        if (["status", "startDate", "facility_id", "provider_id", "customer_data.name", "customer_data.email", "customer_data.phone", "customer_data.address", "customer_data.notes", "details.notes"].includes(items[i].dataField)) {
          if(items[i].dataField == "customer_data.address" && !showCustomerAddress) continue
          items[i].visible = true;
        }
        if (items[i].dataField == "endDate") {
          items[i].visible = false
        }
          
      } else {
        items[i].visible = false;
        if (["appointment_type", "startDate", "endDate", "facility_id", "provider_id"].includes(items[i].dataField)) {
          if (items[i].dataField == "customer_data.address" && !showCustomerAddress) {
            continue
          }
          items[i].visible = true;
        }
      }
    }
    form.option('items', items);
    form.option('visible', true);
  }, [])

  const onAppointmentSave = (e) => {
    props.onAppointmentSave(e.appointmentData);
  }

  const onAppointmentSaving = (e) => {
    let cd = new Date();
    let apptData = null;
    if (e.newData) {
      apptData = e.newData
    }
    if (e.appointmentData) {
      apptData = e.appointmentData
    }
    let { startDate, endDate} = apptData

    let sd = new Date(startDate);
    let ed = new Date(endDate);
    /*let pd = new Date(startDate);
    let rd = new Date(returnDate);
    let dd = new Date(destinationDate);*/
    if ((sd < cd) || (ed < cd) /*|| (pd < cd)*/) {
      notify("Cannot modify the entry because is in the past.", 'error', 3000);
      e.cancel = true;
      return;
    }
  }

  const onAppointmentDelete = (e) => {
    props.onAppointmentDelete(e.appointmentData);
  }


  const calculateEndDate = useCallback( form => {
    const formSelectedServices = form.option().formData.appointment_type
    if (formSelectedServices?.includes(zeroId)) {
      form.updateData('endDate', null)
      return // do not calculate endDate for unavailable appointment, let owner to select endDate in the form
    }
    let sd = new Date(form.option().formData.startDate)
    const services = props.services

    if(services && formSelectedServices){
      let totalDuration = 0
      for(let i in formSelectedServices) {
        const x = services.filter( s => s.service_id === formSelectedServices[i])
        totalDuration += x[0].details.duration
      }
      const ed = new Date(sd.getTime() + 60*totalDuration*1000) // sum of service durations added to startDate
      form.updateData('endDate', ed)
      // console.log("calculated service endDate: ", form.option().formData.endDate)
    }
  }, [props.services])


  const fetchLatestAppointmentData = useCallback( (user, e) => {
    getAppointment(
      user,
      e.appointmentData.appointment_id,
      (data)=> {
        console.log("fetchLatestAppointmentData:", data);
        if (injectDataInAppointment(data, props.resources, props.services)) {
          console.log("previous appointment data", e.appointmentData)
          console.log("latest appointment data", data)
          e.appointmentData = data
          e.form.updateData(data)
          updateForm(e);
        } else {
          // reloading everything
          console.error("Missing resource", data);
          e.popup.hide();
          notify("Please refresh your screen", 'warning', 3000);
        }
      },
      err=> {
        e.popup.hide();
        notify("Please refresh your screen", 'warning', 3000);
      }
    )
  }, [props.resources, props.services, autocompleteService])

  const onAppointmentFormOpening = useCallback((e) => {
    if (e.appointmentData) {
        activeFacilities = props.facilityList.filter(facility => facility.resource_details.active)
        if(!showFacilityOption && showCustomerAddress) {
          for (let f of activeFacilities) {
            if (f.resource_id == "00000000-0000-0000-0000-000000000001"){
              f['resource_details']['default'] = true
            } else {
              f['resource_details']['default'] = false
            }
          }
      }
      if (e.appointmentData.appointment_id) {
        e.form.option('items', []);
        e.form.option('visible', false);
        fetchLatestAppointmentData(user, e);
        if (e.appointmentData.facility_id == "00000000-0000-0000-0000-000000000001") {
          if(activeFacilities.length>0){
            activeFacilities = activeFacilities.filter(f => f.resource_id == "00000000-0000-0000-0000-000000000001")
          }
        }
      } else {
        if (!showFacilityOption && showCustomerAddress) {
           activeFacilities = activeFacilities.filter(f => f.resource_id == "00000000-0000-0000-0000-000000000001")
        }
        updateForm(e);
      }
      fetchAvailableResources(user, e.appointmentData)
    }
  }, [autocompleteService])

  const updateForm = useCallback( (e) => {
    let buttons = e.popup.option("toolbarItems");
    buttons[0].options.text="Save"
    //buttons[0].options.disabled=true
    e.popup.option("toolbarItems", buttons);
    let { startDate, endDate } = e.appointmentData;
    let cd = new Date();
    let new_appointment = true;
    let has_facility = true;
    // cd.setHours(cd.getHours() + 1);
    if (!e.appointmentData.appointment_type) {
      let sd = new Date(startDate);
      if ((sd < cd) && (e.appointmentData.facility_id)) {
        notify("Cannot add entries in the past.", 'warning', 3000);
        e.cancel = true;
        return;
      }
      if (!e.appointmentData.facility_id) {
        sd = cd;
        const defaultFacilityId = activeFacilities.filter(f => f.resource_details.default)[0].resource_id
        e.form.updateData('facility_id', defaultFacilityId)
        if(!showFacilityOption && showCustomerAddress) {
          e.form.updateData('facility_id', "00000000-0000-0000-0000-000000000001")  
        }
      }

      e.form.updateData('startDate',sd);

      for (let i = 0; i < props.facilityList.length;i++ ) {
        if (props.facilityList[i].resource_id === e.appointmentData.facility_id) {
          e.form.updateData('facility', props.facilityList[i]);
        }
      }
      e.form.updateData('appointment_id',crypto.randomUUID());
      e.form.updateData('status','pending');
    } else {
      let sd = new Date(startDate);
      let ed = new Date(endDate);

      if ((sd < cd) || (ed < cd) /*|| (pd < cd)*/) {
        notify("Past entries are read-only.", 'warning', 3000);
        e.cancel = true;
        e.popup.hide();
        return;
      }
      e.form.updateData('startDate',new Date(startDate));
      e.form.updateData('endDate',new Date(endDate));

      new_appointment = false;
      if (e.appointmentData.facility_id === "00000000-0000-0000-0000-000000000000") {
        has_facility = false;
      }
    }

    if (!(e.appointmentData.customer_data)) {
      e.form.updateData('customer_data', {});
    }
    e.form.option('colCount', 1);
    e.form.option('colCountByScreen', { xs: 1, sm: 1, md: 1, lg: 1});
    console.log(props.services);
    e.form.option('items', [
      {
        label: {
          text: 'Entry Type',
        },
        editorType: 'dxTagBox',
        dataField: 'appointment_type',
        editorOptions: {
          dataSource: props.services,
          displayExpr: 'details.service_title',
          valueExpr: 'service_id',
          placeholder: false,
          disabled: false,
          fieldTemplate: 'service_select_field',
          itemTemplate: 'service_select_item',
          dropDownOptions: {
            height: (props.services.length*76+20).toString()+"px"
          },
          onItemClick(args) {
            const selectedAT = e.form.getEditor('appointment_type').option().value
            if (selectedAT.at(-1) === zeroId) {
              e.form.updateData('appointment_type', [zeroId])
            } else {
              e.form.updateData('appointment_type', selectedAT.filter(s => s !== zeroId))
            }
          },
          onValueChanged(args) {
            calculateEndDate(e.form)
            setForm(e.form, args.value);
          },
        },
      },
      {
        label: {
          text: 'Status',
        },
        editorType: 'dxSelectBox',
        dataField: 'status',
        visible: false,
        editorOptions: {
          // items: new_appointment?props.appointmentStatusValues.filter(val => { return (val.requires_facility === false) && (val.requires_existing_appointment === false); })
          //                       :(has_facility?props.appointmentStatusValues
          //                                    :props.appointmentStatusValues.filter(val => { return (val.requires_facility === false)})),
          items: props.appointmentStatusValues,
          displayExpr: 'text',
          valueExpr: 'id',
          onValueChanged(args) {
            setForm(e.form, args.value);
          },
        },
      },
      {
        dataField: 'startDate',
        label: { text: 'Start Date and Time' },
        editorType: 'dxDateBox',
        visible: false,
        isRequired: true,
        editorOptions: {
          min: cd,
          width: '100%',
          type: 'datetime',
          onValueChanged(event) {
            calculateEndDate(e.form)
          },
        },
      },
         {
        dataField: 'endDate',
        label: { text: 'End Date and Time' },
        editorType: 'dxDateBox',
        visible: false,
        isRequired: true,
        editorOptions: {
          min: cd,
          width: '100%',
          type: 'datetime'
        },
      },
      {
        label: {
          text: 'Facility',
        },
        dataField: 'facility_id',
        editorType: 'dxSelectBox',
        visible: false,
        isRequired: true,
        editorOptions: {
          items: activeFacilities,
          displayExpr: 'resource_details.name',
          defaultValue: 'resource_details.name',
          valueExpr: 'resource_id',
          fieldTemplate: 'facility_select_field',
          itemTemplate: 'facility_select_item',
          dropDownOptions: {
            height: (activeFacilities.length*76+20).toString()+"px"
          },
          onValueChanged(args) {
            let vl = args.component.getDataSource().items();
            console.log("data source: ", vl)
            for (let i = 0; i < vl.length;i++ ) {
              if (vl[i].resource_id === args.value) {
                e.form.updateData('facility', vl[i]);
              }
            }
            e.form.updateData('facility_id', args.value);
            try {
              // e.form.itemOption('provider_id', 'editorOptions', {disabled: args.value === "00000000-0000-0000-0000-000000000000"})
              if (args.value === "00000000-0000-0000-0000-000000000000") {
                e.form.updateData('provider_id', null);
                e.form.updateData('provider', null);
                if (new_appointment) {
                  e.form.getEditor('status').option('items', props.appointmentStatusValues.filter(val => { return (val.requires_facility === false) && (val.requires_existing_appointment === false); }));
                } else {
                  e.form.getEditor('status').option('items', props.appointmentStatusValues.filter(val => { return (val.requires_facility === false); }));
                }

              } else {
                if (new_appointment) {
                  e.form.getEditor('status').option('items', props.appointmentStatusValues.filter(val => { return (val.requires_existing_appointment === false); }));
                } else {
                  e.form.getEditor('status').option('items', props.appointmentStatusValues);
                }

              }
            } catch {
              console.warn("Form item not found");
            }
          },
        },
      },
      {
        label: {
          text: 'Team Member',
        },
        dataField: 'provider_id',
        editorType: 'dxSelectBox',
        visible: false,
        editorOptions: {
          // disabled: (e.appointmentData.facility_id === "00000000-0000-0000-0000-000000000000"),
          items: props.providerList,
          dropDownOptions: {
            height: (props.providerList.length*76+20).toString()+"px"
          },
          displayExpr: 'resource_details.name',
          defaultValue: 'resource_details.name',
          valueExpr: 'resource_id',
          fieldTemplate: 'provider_select_field',
          itemTemplate: 'provider_select_item',
          onValueChanged(args) {
            for (let i = 0; i < props.providerList.length; i++ ) {
              if (props.providerList[i].resource_id === args.value) {
                // Next if statemet is needed because if the driver is changed and
                // the new driver has no picture the picture from the previous driver
                // is not reset. It looks like updateData as a cumulative effect.
                if (!props.providerList[i].resource_details.picture) {
                  e.form.updateData('provider.resource_details.picture', null);
                }
                e.form.updateData('provider', props.providerList[i]);
              }
            }
            e.form.updateData('provider_id', args.value);
          },
        },
      },
      {
        label: {
          text: 'Customer Name',
        },
        dataField: 'customer_data.name',
        editorType: 'dxAutocomplete',
        isRequired: true,
        visible: false,
        editorOptions: {
          accessKey: "some-val",
          items: props.customerList,
          displayExpr: 'resource_details.name',
          defaultValue: 'resource_details.name',
          valueExpr: 'resource_details.name',
          showDropDownButton: true,
          onItemClick(args){
            selectedCustomer['customer_id'] = args.itemData.resource_id
            selectedCustomer['email'] = args.itemData.resource_details.email
            selectedCustomer['name'] = args.itemData.resource_details.name
            selectedCustomer['phone'] = args.itemData.resource_details.phone
            selectedCustomer['address'] = args.itemData.resource_details.address
            selectedCustomer['notes'] = args.itemData.resource_details.notes
            console.log('args.itemData.resource_details', args.itemData.resource_details)

            e.form.updateData('customer_id', selectedCustomer['customer_id'])
            e.form.updateData('customer_data.email', args.itemData.resource_details.email);
            e.form.updateData('customer_data.phone', args.itemData.resource_details.phone);
            if (e.form.getEditor('customer_data.address')) {
              e.form.updateData('customer_data.address', args.itemData.resource_details.address);
            }
            e.form.updateData('customer_data.notes', args.itemData.resource_details.notes);

            e.form.getEditor('customer_data.email').option('readOnly', true)
            e.form.getEditor('customer_data.phone').option('readOnly', true)
            if (e.form.getEditor('customer_data.address')) {
              e.form.getEditor('customer_data.address').option('readOnly', true)
            }
            e.form.getEditor('customer_data.notes').option('readOnly', true)
          },
          onInput(args){
            if(args.event.currentTarget.value.trim() !== selectedCustomer['name']){
              e.form.updateData('customer_id', false)
              e.form.getEditor('customer_data.email').option('readOnly', false)
              e.form.getEditor('customer_data.phone').option('readOnly', false)
              if (e.form.getEditor('customer_data.address')) {
                e.form.getEditor('customer_data.address').option('readOnly', false)
              }
              e.form.getEditor('customer_data.notes').option('readOnly', false)

              e.form.updateData('customer_data.email', '');
              e.form.updateData('customer_data.phone', '');
              if (e.form.getEditor('customer_data.address')) {
                e.form.updateData('customer_data.address', '');
              }
              e.form.updateData('customer_data.notes', '');
            } else {
              e.form.updateData('customer_id', selectedCustomer['customer_id'])
              e.form.updateData('customer_data.email', selectedCustomer['email']);
              e.form.updateData('customer_data.phone', selectedCustomer['phone']);
              if (e.form.getEditor('customer_data.address')) {
                e.form.updateData('customer_data.address', selectedCustomer['address']);
              }
              e.form.updateData('customer_data.notes', selectedCustomer['notes']);

              e.form.getEditor('customer_data.email').option('readOnly', true)
              e.form.getEditor('customer_data.phone').option('readOnly', true)
              if (e.form.getEditor('customer_data.address')) {
                e.form.getEditor('customer_data.address').option('readOnly', true)
              }
              e.form.getEditor('customer_data.notes').option('readOnly', true)
            }
          }
        },
      },
      {
        label: {
          text: 'Customer Address',
        },
        dataField: 'customer_data.address',
        editorType: 'dxAutocomplete',
        isRequired: false,
        visible: false,
        editorOptions: {
          showDropDownButton: true,
          activeStateEnabled: true,
          valueExpr: 'description',
          dataSource: predictionResults,
          onInput(e) {
            fetchPredictions(e.event.currentTarget.value, formRef);
          }
        }
      },
      {
        label: {
          text: 'Customer Email',
        },
        dataField: 'customer_data.email',
        editorType: 'dxTextBox',
        isRequired: true,
        visible: false,
        editorOptions: {
          onValueChanged(args) {
            e.form.updateData('customer_data.email', args.value.trim());
          },
        },
      },
      {
        label: {
          text: 'Customer Phone',
        },
        dataField: 'customer_data.phone',
        editorType: 'dxTextBox',
        isRequired: true,
        visible: false,
        editorOptions: {
          mask: '(000) 000-0000',
          onValueChanged(args) {
            e.form.updateData('customer_data.phone', args.value);
          },
        },
      },
      {
        label: {
          text: 'Customer Internal Notes',
        },
        visible: false,
        dataField: 'customer_data.notes',
        editorType: 'dxTextArea',
        editorOptions: {
          onValueChanged(args) {
            if(args.value){
              e.form.updateData('customer_data.notes', args.value.trim());
            }
          },
        },
      },
      {
        label: {
          text: 'Special Requests',
        },
        dataField: 'details.notes',
        editorType: 'dxTextArea',
        visible: false,
        editorOptions: {
          placeholder: 'Add things important to mention...',
          onValueChanged(args) {
            if(args.value) {
              e.form.updateData('details.notes', args.value.trim());
            }
          },
        },
      },
    ]);
    setForm(e.form, e.appointmentData.appointment_type);
  }, [activeFacilities])


  const isResourceAvailable = (e, availableResources) => {
    if(!availableResources) return true
    if(e.data === null) return true
    const currentId = e.data.resource_id
    if(!currentId) return true
    const resourcesObj = availableResources[e.data.resource_type+'s']
    if(!resourcesObj) return true
    let available = true
    if(currentId !== '00000000-0000-0000-0000-000000000000') {
      available = resourcesObj[currentId]
    }
    return available
  }



  const FacilitySelectField = useCallback( (e) => {
    const available = isResourceAvailable(e, availableResources)
    if(e.data == null) return
    let facilityImg = e.data.resource_details.picture
    if(!facilityImg) {
      if(e.data.resource_id == '00000000-0000-0000-0000-000000000001') {
        facilityImg = '/DefaultFacility2.png'
      } else {
        facilityImg = '/DefaultFacility.png'
      }
    }
    return (
      <div className="d-flex flex-row align-items-center">
        {e.data && <div
          className="ms-3"
            style={{
              backgroundColor: "#fff",
              backgroundImage: `url("${facilityImg}")`,
              backgroundSize: 'cover',
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              width: "50px",
              height: "43px",
              marginTop: "3px",
              marginBottom: "3px",
              borderRadius: "50%",
            }}
        />}
        <TextBox
          className="driver-select-field-name"
          placeholder="Select a facility..."
          defaultValue={e.data && e.data.resource_details.name}
          readOnly={true}
        />
          { !available && <i className="fa fa-circle text-warning"></i> }
      </div>
    );
  }, [availableResources])

  const FacilitySelectItem = useCallback((e) => {
    const available = isResourceAvailable(e, availableResources)
    let facilityImg = e.data.resource_details.picture
    if(!facilityImg) {
      if(e.data.resource_id == '00000000-0000-0000-0000-000000000001') {
        facilityImg = '/DefaultFacility2.png'
      } else {
        console.log('this condition has been met')
        facilityImg = '/DefaultFacility.png'
      }
    }
    return (
      <div className="d-flex flex-row align-items-center">
        <div
          style={{
            backgroundColor: "#fff",
            backgroundImage: `url("${facilityImg}")`,
            backgroundSize: 'cover',
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            width: "50px",
            height: "43px",
            marginTop: "3px",
            marginBottom: "3px",
            borderRadius: "50%",
          }}
        />
        <div className="d-flex align-items-center justify-content-between w-100">
          { e.data && <div className="ms-3">{e.data.resource_details.name}</div> }
          { !available && <i className="fa fa-circle text-warning"></i> }
        </div>
      </div>
    );
  }, [availableResources])


  const ProviderSelectField = useCallback( (e) => {
    const available = isResourceAvailable(e, availableResources)
    return (
      <div className="d-flex flex-row align-items-center">
        {e.data && <div
          className="ms-3"
          style={{
            backgroundColor: "#fff",
            backgroundImage: `url("${e.data && e.data.resource_details.picture?e.data.resource_details.picture:'/DefaultAvatar.png'}")`,
            backgroundSize: 'cover',
            width: "50px",
            height: "43px",
            marginTop: "3px",
            marginBottom: "3px",
            borderRadius: "50px",
          }}
        />}
        <TextBox
          className="driver-select-field-name"
          placeholder="Select a team member..."
          defaultValue={e.data && e.data.resource_details.name}
          readOnly={true}
        />
          { !available && <i className="fa fa-circle text-warning"></i> }
      </div>
    );
  }, [availableResources])

  const ProviderSelectItem = useCallback( (e) => {
    const available = isResourceAvailable(e, availableResources)
    return (
      <div className="d-flex flex-row align-items-center">
        <div
          style={{
            backgroundColor: "#fff",
            backgroundImage: `url("${e.data && e.data.resource_details.picture?e.data.resource_details.picture:'/DefaultAvatar.png'}")`,
            backgroundSize: 'cover',
            width: "50px",
            height: "43px",
            marginTop: "3px",
            marginBottom: "3px",
            borderRadius: "50px",
          }}
        />

        <div className="d-flex align-items-center justify-content-between w-100">
          { e.data && <div className="ms-3">{e.data.resource_details.name}</div> }
          { !available && <i className="fa fa-circle text-warning"></i> }
        </div>

      </div>
    );
  }, [availableResources])

  const ServiceSelectField = useCallback( (e) => {
    const available = isResourceAvailable(e, availableResources)
    return (
      <div className="d-flex flex-row align-items-center">
        <TextBox
          className="driver-select-field-name"
          placeholder={ e.data.length === 0 ? "Select an entry type ..." : "" }
          readOnly={true}
        />
          { !available && <i className="fa fa-circle text-warning"></i> }
      </div>
    );
  }, [availableResources])

  const ServiceSelectItem = useCallback( (e) => {
    const available = isResourceAvailable(e, availableResources)
    return (
      <div className="d-flex flex-row align-items-center">
        <div
          style={{
            backgroundColor: "#fff",
            backgroundImage: `url("${e.data && e.data.details.picture?e.data.details.picture:'/DefaultAvatar.png'}")`,
            backgroundSize: 'cover',
            width: "50px",
            height: "43px",
            marginTop: "3px",
            marginBottom: "3px",
            borderRadius: "50px",
          }}
        />

        <div className="d-flex align-items-center justify-content-between w-100">
          { e.data && <div className="ms-3">{e.data.details.service_title}</div> }
          { !available && <i className="fa fa-circle text-warning"></i> }
        </div>

      </div>
    );
  }, [availableResources])

  
  return (
    <React.Fragment>
      <Scheduler
        ref={schedulerRef}
        id="scheduler"
        dataSource={props.appointmentData}
        onAppointmentFormOpening={onAppointmentFormOpening}
        onAppointmentAdding={onAppointmentSaving}
        onAppointmentAdded={onAppointmentSave}
        crossScrollingEnabled={false}
        onAppointmentUpdated={onAppointmentSave}
        onAppointmentUpdating={onAppointmentSaving}
        onAppointmentDeleted={onAppointmentDelete}
        onAppointmentContextMenu={onAppointmentContextMenu}
        onAppointmentDblClick={onAppointmentContextMenu}
        onCellContextMenu={onCellContextMenu}
        appointmentComponent={ScheduleDataCell}
        appointmentTooltipComponent={ScheduleDataTooltip}
        resourceCellComponent={ScheduleResourceCell}
        adaptivityEnabled={true}
        groups={groups}
        defaultCurrentView={isPhone ? "day" :"week"}
        allDayPanelMode="hidden"
        currentDate={currentSchedulerDate}
        startDayHour={openingHour}
        endDayHour={closingHour}
      >

        <View
          type='day'
          cellDuration={60}
          groupOrientation='vertical'
        />
        <View
          type='week'
          cellDuration={60}
          groupOrientation='vertical'
        />
        <Resource
          label="Facility"
          fieldExpr="facility_id"
          valueExpr="resource_id"
          displayExpr="resource_details.name"
          dataSource={props.facilityList}
          allowMultiple={false}
        />
        <Resource
          label="Team Member"
          fieldExpr="provider_id"
          valueExpr="resource_id"
          displayExpr="resource_details.name"
          dataSource={props.providerList}
          allowMultiple={false}
        />
        <Resource
          label="Entry Type"
          fieldExpr="appointment_type"
          dataSource={props.services}
          allowMultiple={false}
          useColorAsDefault={true}
        />
        <Editing
          allowAdding={true}
          allowDeleting={true}
          allowDragging={false}
          allowResizing={false}
          allowUpdating={true}
        />

        <Template name="provider_select_field" component={ProviderSelectField} />
        <Template name="provider_select_item" component={ProviderSelectItem} />
        <Template name="service_select_field" component={ServiceSelectField} />
        <Template name="service_select_item" component={ServiceSelectItem} />
        <Template name="facility_select_field" component={FacilitySelectField} />
        <Template name="facility_select_item" component={FacilitySelectItem} />
      </Scheduler>
      <ContextMenu
        ref={contextMenuRef}
        dataSource={contextMenuItems}
        width={200}
        target={appointmentClassName}
        disabled={false}
        onItemClick={onContextMenuItemClick}
        itemComponent={AppointmentMenuTemplate}
      />
    </React.Fragment>
)}
