import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useSearchParams } from 'react-router-dom'
import { useNavigate } from 'react-router';
import { updateResource, deleteResource } from '../../../api/api';
import {Form,
  Label,
  SimpleItem,
  Item,
  EmptyItem,
  GroupItem,
    ButtonItem,
    RequiredRule,
    EmailRule} from 'devextreme-react/form';
import { Switch } from "devextreme-react/switch";
import { useAuth } from '../../../contexts/auth';
import {getUserDetails, updateUserDetails, weekDays} from '../../../api/api'
import { parseHours } from '../../../utils/miscUtil';
import { Popup, Position, ToolbarItem } from 'devextreme-react/popup';
import RadioGroup from 'devextreme-react/radio-group';
import Button from 'devextreme-react/button';
import notify from 'devextreme/ui/notify';
import {useMap, useMapsLibrary} from '@vis.gl/react-google-maps';
import devices from 'devextreme/core/devices';

const isPhone = devices.current().deviceType === 'phone';


const SchedulingSettings = () => {
  const [schedulingSettings, setSchedulingSettings] = useState({})
  const [formEditData, setFormEditData] = useState()
  const [showPopupEditSettings, setShowPopupEditSettings] = useState(false);
  const [isBtnDisabled, setIsBtnDisabled] = useState(false)
  const [loaded, setLoaded] = useState(false)
  const formRef = useRef(null);
  const { user } = useAuth();
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const map = useMap();
  const places = useMapsLibrary('places');
  const routes = useMapsLibrary('routes');

  const [predictionResults, setPredictionResults] = useState([])
  const [autocompleteService, setAutocompleteService] = useState(null);
  const [sessionToken, setSessionToken] = useState();

  useEffect( () => {
    getUserDetails(user, data => {
      if('scheduling_settings' in data) {
        setSchedulingSettings(data.scheduling_settings);
        setFormEditData(new Object(data.scheduling_settings));
        console.log('data.scheduling_settings: ', data.scheduling_settings)
      }
      setLoaded(true);
      if (searchParams.get('setupPopup') === 'settings') {
        onEdit();
      }
    }, err => {
      console.log(err)
      setLoaded(true)
    })
  }, [])

  useEffect(() => {
    if (!places || !routes) return;
    setAutocompleteService(new places.AutocompleteService());
    setSessionToken(new places.AutocompleteSessionToken());
  }, [map, places, routes]);


  const onSaveScheduling = (e) => {
    let result = formRef.current?.instance.validate()
    if(result.isValid){
      console.log('e-onSaveScheduling: ', e)
      setIsBtnDisabled(true)
      const updaterObj = {}
      updaterObj['scheduling_settings'] = formRef.current.instance.option().formData
      updateFacility(formRef.current.instance.option().formData.show_customer_address_option)
      updateUserDetails(
        user,
        updaterObj,
        (data) => {
          onCancel()
          setSchedulingSettings(data.scheduling_settings)
          setFormEditData(new Object(data.scheduling_settings));
          if (searchParams.get('setupMode')) {
            navigate('/home?setupMode=true');
          }
        },
        (err) => console.log('err: ', err)
      )
    } else {
      notify("Please correct the invalid fields before proceeding", 'error', 2000);
    }


  }

  const saveSettingsButtonOptions = {
    text: 'Save',
    type: 'default',
    width: '100%',
    onClick: onSaveScheduling,
  }

  const onCancel = () => {
    setShowPopupEditSettings(false)
    // formRef.current?.instance.reset(schedulingSettings)
  }

  const cancelButtonOptions = {
    disabled: false,
    text: 'Cancel',
    width: '100%',
    onClick: onCancel
  }

  const renderSettingVal = (varName) => {
      if(schedulingSettings[varName] && ( schedulingSettings[varName] !== null || schedulingSettings[varName] !== '' ) ) {
        return schedulingSettings[varName]
      }
      return "n/a"
  }

  const fetchPredictions = useCallback(
    async (inputValue, form) => {
      if (!autocompleteService || !inputValue) {
        while (predictionResults.length > 0) {
          predictionResults.pop();
        }
        return;
      }

      const request = {input: inputValue, sessionToken};
      const response = await autocompleteService.getPlacePredictions(request);

      //TODO: This code doesn't seem right but it works

      /*let items = form.option('items');
      for (let i = 0; i < items.length; i++) {
        if (["ride_details.start_address", "ride_details.end_address"].includes(items[i].dataField)) {
          items[i].editorOptions.dataSource = response.predictions;
        }
      }
      form.option('items', items);*/
      while (predictionResults.length > 0) {
        predictionResults.pop();
      }
      for (let i = 0; i < response.predictions.length; i++) {
        predictionResults.push(response.predictions[i]);
      }
    }, [autocompleteService, sessionToken]
  )

  const onEdit = () => {
    setIsBtnDisabled(false)
    setShowPopupEditSettings(true);
  }

  const updateFacility = useCallback( (val) => {
    if(user) {
      const customerFacility = {}
      customerFacility['owner_id'] = user['username']
      customerFacility['resource_id'] = "00000000-0000-0000-0000-000000000001"
      customerFacility['resource_type'] = "facility"
      customerFacility['resource_details'] = {}
      customerFacility['resource_details']['active'] = true
      customerFacility['resource_details']['name'] = "Customer Provided Address"

      if(val == true){
        updateResource(user, customerFacility, data => console.log('facility saved: ', data), err => console.info('err in facility save: ', err));
      } else if(val == false) {
        deleteResource(user, customerFacility, data => console.log('facility deleted: ', data), err => console.info('err in facility delete: ', err))
      }
    }
  }, [user])


  if(loaded) {
    return (
      <div className="content-block mt-5">
        <div className="d-flex flex-row justify-content-between align-items-center">
          <h2 className="">Settings</h2>
          <Button
              type="default"
              text="Edit"
              onClick={onEdit}
          />
          </div>
          <hr className="mt-0 mb-5"></hr>
          <div className="">
            <div className="dx-fieldset">
              <div className="dx-fieldset-header">Basic settings</div>


              <div className="dx-field" style={{flexDirection:"column"}}>
                <div className="dx-field-label w-100">Cancelation Policy Text</div>
                <div className="dx-field-value w-100">{ renderSettingVal('cancelation_policy_text', '100% refund for canceling more than 24 hours in advance') }</div>
              </div>
              <div className="dx-field" style={{flexDirection:"column"}}>
                <div className="dx-field-label w-100">Time Zone</div>
                <div className="dx-field-value w-100">{ renderSettingVal('timezone', '') }</div>
              </div>
              <div className="dx-field" style={{flexDirection:"column"}}>
                <div className="dx-field-label w-100">Auto-accept Bookings</div>
                <div className="dx-field-value w-100">{ schedulingSettings['auto_accept'] ? 'Enabled' : 'Disabled' }</div>
              </div>
              <div className="dx-field" style={{flexDirection:"column"}}>
                <div className="dx-field-label w-100">Multiple Service Selection</div>
                <div className="dx-field-value w-100">{ schedulingSettings['multiple_service_selection'] ? 'Enabled' : 'Disabled' }</div>
              </div>
              <div className="dx-field" style={{flexDirection:"column"}}>
                <div className="dx-field-label w-100">Allow to Pick Team Member</div>
                <div className="dx-field-value w-100">{ schedulingSettings['service_provider_option'] ? 'Enabled' : 'Disabled' }</div>
              </div>
              <div className="dx-field" style={{flexDirection:"column"}}>
                <div className="dx-field-label w-100">Allow to Pick Facility</div>
                <div className="dx-field-value w-100">{ schedulingSettings['facility_option'] ? 'Enabled' : 'Disabled' }</div>
              </div>
              <div className="dx-field" style={{flexDirection:"column"}}>
                <div className="dx-field-label w-100">Record Customer Address</div>
                <div className="dx-field-value w-100">{ schedulingSettings['show_customer_address_option'] ? 'Enabled' : 'Disabled' }</div>
              </div>
              <div className="dx-field" style={{flexDirection:"column"}}>
                <div className="dx-field-label w-100">Show Availability to Public</div>
                <div className="dx-field-value w-100">{ schedulingSettings['show_availability_to_public'] ? 'Enabled' : 'Disabled' }</div>
              </div>              
            </div>

            
            <div className="dx-fieldset">
            <div className="dx-fieldset-header">Business Hours</div>
              <div className="dx-field" style={{flexDirection:"column"}}>
                <div className="dx-field-label w-100">Opened/Closed</div>
                { (schedulingSettings.business_hours?.opening && schedulingSettings.business_hours?.closing) ?
                  <div className="dx-field-value w-100">{ parseHours(schedulingSettings.business_hours.opening)} - { parseHours(schedulingSettings.business_hours.closing)}</div>
                  :
                  <div className="dx-field-value w-100">n/a</div>
                }
              </div>
              <div className="dx-field" style={{flexDirection:"column"}}>
                <div className="dx-field-label w-100">Working Days</div>
                { (schedulingSettings.business_hours?.working_days?.length > 0) ?
                  <div className="dx-field-value w-100">{ 
                    schedulingSettings.business_hours?.working_days.map( (item, indx) => {
                      const daysLength = schedulingSettings.business_hours?.working_days.length
                      let val = weekDays.filter( (d) => d['ID'] === item)[0]['Name']
                      if(daysLength == 1) {
                        return val
                      }
                      if (daysLength === indx + 1) {
                        return ' and ' + val + '.'
                      } else {
                        return val + ', '
                      }
                    })
                  }</div>
                  :
                  <div className="dx-field-value w-100">n/a</div>
                }
              </div>
            </div>

            <div className="dx-fieldset">
              <div className="dx-fieldset-header">Payment</div>
              <div className="dx-field" style={{flexDirection:"column"}}>
                <div className="dx-field-label w-100">Reservation Payment Link</div>
                <div className="dx-field-value w-100">{ renderSettingVal('reservation_payment_link', 10) }</div>
              </div>
            </div>
          </div>

          <Popup
            className="text-danger"
            visible={showPopupEditSettings}
            // onHidden={onCloseEditSettingsDialog}
            fullScreen={isPhone}
            dragEnabled={false}
            showCloseButton={false}
            showTitle={true}
            title="Edit Settings"
            container=".dx-viewport"
            width={"auto"}
            maxHeigth={"700px"}
          >
          <Position at="center" my="center"collision="fit" />
            <ToolbarItem
                widget="dxButton"
                toolbar="bottom"
                location="before"
                options={saveSettingsButtonOptions}
                disabled={isBtnDisabled}
            />
            <ToolbarItem
                widget="dxButton"
                toolbar="bottom"
                location="after"
                options={cancelButtonOptions}
            />

            <Form
                ref={formRef}
                colCount={1}
                id="settingsForm"
                width="100%"
                formData={formEditData}
                showRequiredMark={true}
            >
                <GroupItem caption="Basic settings">
                  <SimpleItem dataField="cancelation_policy_text"
                    editorType="dxTextBox"
                    helpText="Brief description of your cancelation policy. Visible to customer when booking rides."
                  >
                    <Label text="Cancelation Policy Text"/>
                  </SimpleItem>
                  <SimpleItem dataField="auto_accept"
                    editorType="dxSwitch"
                    helpText="If enabled, we will auto accept bookings for you when there is an empty slot in the schedule."
                  >
                    <Label text="Auto-accept Bookings"/>
                  </SimpleItem>
                  <SimpleItem dataField="multiple_service_selection"
                    editorType="dxSwitch"
                    helpText="If enabled, customers will be able to select multiple services."
                  >
                    <Label text="Multiple Service Selection"/>
                  </SimpleItem>
                  <SimpleItem dataField="service_provider_option"
                    editorType="dxSwitch"
                    helpText="If enabled, customers will be able to select a team member from a drop down."
                  >
                    <Label text="Allow to Pick Team Member"/>
                  </SimpleItem>
                  <SimpleItem dataField="facility_option"
                    editorType="dxSwitch"
                    helpText="If enabled, customers will be able to select a facility from a drop down."
                  >
                    <Label text="Allow to Pick Facility"/>
                  </SimpleItem>
                  <SimpleItem dataField="show_customer_address_option"
                    editorType="dxSwitch"
                    helpText="If enabled, customers will be asked to provide their address."
                    editorOptions={{
                      // onValueChanged: e => updateFacility(e)
                    }}
                  >
                    <Label text="Record Customer Address"/>
                  </SimpleItem>
                  <SimpleItem dataField="show_availability_to_public"
                    editorType="dxSwitch"
                    helpText="If enabled, only available hours are listed to the customer."
                  >
                    <Label text="Show Availability to Public"/>
                  </SimpleItem>                  
                </GroupItem>

                <GroupItem caption="Business Hours" colCount={2}>
                    <SimpleItem
                        colSpan={1}
                        dataField="business_hours.opening"
                        editorType="dxDateBox"
                        editorOptions={{
                            type: 'time',
                            onOptionChanged(e){
                              console.log('on selection ch:', e)
                            }
                        }}
                    >
                        <Label text="Opened"/>
                        {/* <RequiredRule /> */}
                    </ SimpleItem>
                    <SimpleItem
                        colSpan={1}
                        dataField="business_hours.closing"
                        editorType="dxDateBox"
                        editorOptions={{
                            type: 'time',
                        }}
                    >
                        <Label text="Closed"/>
                        {/* <RequiredRule /> */}
                    </ SimpleItem>

                    <SimpleItem
                      dataField="business_hours.working_days"
                      editorType="dxTagBox"
                      editorOptions={{
                        accessKey: 'working-hours',
                        dataSource: weekDays.sort(),
                        valueExpr: 'ID',
                        displayExpr: 'Name',
                        onItemClick(){
                          const selectedData = formRef.current.instance.option().formData.business_hours.working_days.sort()
                          formRef.current?.instance.updateData('business_hours.working_days', selectedData)  
                          formRef.current?.instance.getEditor('business_hours.working_days').repaint() 
                        }
                      }}
                    >
                      <Label text="Working Days" />
                    </SimpleItem>
                </GroupItem>
                <GroupItem caption="Payment">
                  <SimpleItem 
                    dataField="reservation_payment_link"
                    editorType="dxTextBox"
                    editorOptions={{min: 0}}
                    helpText="Stripe payment link. Adding this link will require your customers to do an online payment in order to confirm the appointment."
                  >
                    <Label text="Reservation Payment Link"/>
                  </SimpleItem>
                </GroupItem>
            </Form>
          </Popup>

      </div>

    )
  }
}

export default SchedulingSettings
