import { HomePage, ProvidersPage, CustomersPage, ProfilePage, FacilitiesPage, SchedulePage, BillingPage, ServicesPage, PPPage, TOSPage } from './pages';
import { withNavigationWatcher } from './contexts/navigation';

const routes = [
    {
        path: '/providers',
        element: ProvidersPage
    },
    {
        path: '/facilities',
        element: FacilitiesPage
    },
    {
        path: '/customers',
        element: CustomersPage
    },
    {
        path: '/schedule',
        element: SchedulePage
    },
    {
        path: '/profile',
        element: ProfilePage
    },
    {
        path: '/billing',
        element: BillingPage
    },
    {
        path: '/home',
        element: HomePage
    },
    {
        path: '/legal/tos',
        element: TOSPage
    },
    {
        path: '/legal/pp',
        element: PPPage
    },
    {
        path: '/services',
        element: ServicesPage
    }
];

export default routes.map(route => {
    return {
        ...route,
        element: withNavigationWatcher(route.element, route.path)
    };
});
